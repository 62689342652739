<div class="nagivation_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <nav class="navbar navbar-expand-lg navbar-light" *ngIf="globals.institute_details!=null">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav" *ngIf="globals.institute_details">
                        <ul class="navbar-nav">
                            <li *ngIf="(globals.institute_details && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2) && globals.institute_details.role_value != 6)"
                                [ngClass]="{'active':globals.currentLink=='/overview-dashboard'}"><a
                                    routerLink="/overview-dashboard">Overview</a></li>

                            <li *ngIf="(globals.institute_details && (globals.institute_details.role_value != 1 && globals.institute_details.role_value != 2 ) && globals.institute_details.role_value != 6)"
                                [ngClass]="{'active':globals.currentLink=='/dashboard'}"><a
                                    routerLink="/dashboard">Dashboard</a></li>

                            <li *ngIf="(globals.institute_details && (globals.institute_details.role_value == 1 
                            || globals.institute_details.role_value == 2
                            || globals.institute_details.role_value == 3 
                            || globals.institute_details.role_value == 6))
                            && (globals.permissions['institute-list']
                            || globals.permissions['add-invite-user'] 
                            || globals.permissions['invite-user-list'])"
                                [ngClass]="{'active':
                                (
                                    (
                                        globals.currentLink.split('/')[2]=='organization' && globals.currentLink.split('/')[3]=='update') || 
                                        globals.currentLink=='/manage/user/connect' || 
                                        globals.currentLink.split('/')[3]=='connection-history' || 
                                        globals.currentLink=='/manage/organization/list' || 
                                        globals.currentLink=='/manage/my-organization/list' || 
                                        (
                                            globals.currentLink.split('/')[2]=='users' || 
                                            (
                                                globals.currentLink.split('/')[2]=='user' && globals.currentLink.split('/')[3]=='edit'
                                            )
                                        ) || 
                                        (
                                            globals.currentLink.split('/')[2]=='cohort' || 
                                            (
                                                globals.currentLink.split('/')[2]=='cohort' && globals.currentLink.split('/')[3]=='edit'
                                            )
                                        ) ||
                                        (
                                            globals.currentLink.split('/')[2]=='subscription' || 
                                            (
                                                globals.currentLink.split('/')[2]=='subscription' && globals.currentLink.split('/')[3]=='edit'
                                            )
                                        ) ||
                                        (
                                            globals.currentLink.split('/')[2]=='organization' || 
                                            (
                                                globals.currentLink.split('/')[2]=='organization' && globals.currentLink.split('/')[3]=='edit'
                                            )
                                           
                                        ) ||

                                        (
                                            globals.currentLink.split('/')[2]=='assigned-user-subscription' 
                                            || 
                                            (
                                                globals.currentLink.split('/')[2]=='assigned-user-subscription' 
                                            )
                                            
                                        ) ||
                                        (
                                            globals.currentLink.split('/')[2]=='assign-user-subscription' 
                                            || 
                                            (
                                                globals.currentLink.split('/')[2]=='assign-user-subscription' 
                                            )
                                            
                                        ) ||
                                        
                                        ((globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2) && globals.currentLink=='/dashboard'))}">
                                <a>Organization <i class="fa fa-caret-down"></i></a>
                                <div class="submenu dropdown-menu">
                                    <div class="width_menu">

                                        <!-- <li *ngIf="(globals.institute_details && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3 || globals.institute_details.role_value == 6)) && (globals.permissions['ownership-list'] || globals.permissions['type-list'] || globals.permissions['system-list'] || globals.permissions['group-list'] || globals.permissions['unit-list'] || globals.permissions['department-list'])"
                                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='organization-ownership' || globals.currentLink.split('/')[2]=='organization-type' || globals.currentLink.split('/')[2]=='department' || globals.currentLink.split('/')[2]=='group')}">
                                <a>Manage <i class="fa fa-caret-down"></i></a>
                                <div class="submenu dropdown-menu">
                                    <div class="width_menu">
                            <li *ngIf="globals.permissions['department-list']"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='department')}">
                                <a routerLink="/manage/department/list">Departments</a>
                            </li>
                            <li *ngIf="globals.permissions['unit-list']"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='unit')}">
                                <a routerLink="/manage/unit/list">Units</a>
                            </li>
                            <li *ngIf="globals.permissions['system-list']"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-system')}">
                                <a routerLink="/manage/organization-system/list">Systems</a>
                            </li>
                            <li *ngIf="globals.permissions['ownership-list']"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-ownership')}">
                                <a routerLink="/manage/organization-ownership/list">Ownerships</a>
                            </li>
                            <li *ngIf="globals.permissions['type-list']"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-type')}">
                                <a routerLink="/manage/organization-type/list">Types</a>
                            </li>
                    </div>
            </div>
            </li> -->
                            <li *ngIf="(globals.institute_details && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2) )&& globals.institute_details.role_value != 6"
                                [ngClass]="{'active':globals.currentLink=='/dashboard'}"><a
                                    routerLink="/dashboard">Dashboard</a></li>

                            <!-- <li *ngIf="(globals.institute_details && globals.institute_details.institute && globals.institute_details.institute.id!='')"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization' && globals.currentLink.split('/')[3]=='update')}">
                                <a routerLink="/manage/organization/update">Update Detail</a>
                            </li> -->
                            <li
                                *ngIf="(globals.permissions['invite-user-list'] || globals.permissions['add-invite-user'] || globals.permissions['user-connection-history'])">
                                <span class="title">Users</span>
                                <ul class="submenu dropdown-menu">
                                    <li *ngIf="globals.permissions['invite-user-list']"
                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && ( globals.currentLink.split('/')[2]=='users' || (globals.currentLink.split('/')[2]=='user' && globals.currentLink.split('/')[3]=='edit')))}">
                                        <a (click)="backToOrg(false)" routerLink="/manage/users">List</a>
                                    </li>
                                    <li *ngIf="globals.permissions['add-invite-user'] && globals.institute_details.role_value !== 6"
                                        [ngClass]="{'active':(globals.currentLink=='/manage/user/connect')}">
                                        <a routerLink="/manage/user/connect">Enroll</a>
                                    </li>
                                    <!-- <li 
                                    [ngClass]="{'active':(globals.currentLink=='/manage/assigned-user-subscription')}">
                                    <a routerLink="/manage/assigned-user-subscription/list">Assign Subscription</a>
                                </li> -->
                                    <!-- <li *ngIf="globals.permissions['user-connection-history']"
                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='user' && globals.currentLink.split('/')[3]=='connection-history')}">
                                        <a routerLink="/manage/user/connection-history">History</a>
                                    </li>
                                    <li>
                                        <a (click)="AssignDCMLicenses()">Assign Licenses</a>
                                    </li> -->
                                </ul>
                            </li>


                            <!-- for chohort -->

                            <li
                                *ngIf="globals.institute_details.role_value != 6 && (globals.permissions['invite-user-list'] || globals.permissions['add-invite-user'] || globals.permissions['user-connection-history'])">
                                <span class="title">Cohorts</span>
                                <ul class="submenu dropdown-menu">
                                    <li *ngIf="globals.permissions['invite-user-list']"
                                        [ngClass]="{'active':(globals.currentLink=='/manage/cohort')}">
                                        <a (click)="backToOrg(false)" routerLink="/manage/cohort">List</a>
                                    </li>


                                    <li *ngIf="globals.permissions['add-invite-user']"
                                        [ngClass]="{'active':(globals.currentLink=='/manage/cohort/connect')}">
                                        <a routerLink="/manage/cohort/connect">Enroll</a>
                                    </li>

                                </ul>
                            </li>

                            <li
                                *ngIf="globals.institute_details.role_value != 6 && ((globals.institute_details.role_value != 1 && globals.institute_details.role_value != 2) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 && (globals.authData.list.length>1)) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2))">
                                <span class="title">Administration</span>
                                <ul class="submenu dropdown-menu">
                                    <li *ngIf="(globals.institute_details.role_value != 1 && globals.institute_details.role_value != 2) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 3 && (globals.authData.list.length>1))"
                                        [ngClass]="{'active':globals.currentLink=='/manage/my-organization/list'}">
                                        <a routerLink="/manage/my-organization/list">My Organization</a>
                                    </li>
                                    <li *ngIf="(globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)"
                                        [ngClass]="{'active':globals.currentLink=='/manage/organization/list'}">
                                        <a routerLink="/manage/organization/list">All Organizations</a>
                                    </li>
                                    <li *ngIf="(globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)"
                                        [ngClass]="{'active':globals.currentLink=='/manage/organization/add'}">
                                        <a routerLink="/manage/organization/add">Enroll Organization</a>
                                    </li>
                                    <!-- <li *ngIf="(globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)"
                                        [ngClass]="{'active':globals.currentLink=='/manage/subscription/list'}">
                                        <a routerLink="/manage/subscription/list">Subscription Packs</a>
                                    </li> -->

                                </ul>
                            </li>

                            <li
                            *ngIf="globals.permissions['subscription-list'] || globals.permissions['add-subscription'] && ((globals.institute_details.role_value != 1 && globals.institute_details.role_value != 2) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 && (globals.authData.list.length>1)) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2))">
                            <span class="title">Subscription Packs</span>
                                <ul class="submenu dropdown-menu">
                                    <li  *ngIf="globals.permissions['subscription-list'] && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3)"
                                        [ngClass]="{'active':(globals.currentLink=='/manage/subscription/list')}">
                                        <a routerLink="/manage/subscription/list">List</a>
                                    </li>
                                    <li  *ngIf="globals.permissions['add-subscription'] && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)"
                                        [ngClass]="{'active':(globals.currentLink=='/manage/subscription/add')}">
                                        <a routerLink="/manage/subscription/add">Add</a>
                                    </li>

                                </ul>
                            </li>

                            <li
                                *ngIf="((globals.institute_details.role_value != 1 && globals.institute_details.role_value != 2) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 && (globals.authData.list.length>1)) || (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2))">
                                <span class="title">User Subscription Management</span>
                                <ul class="submenu dropdown-menu">

                                    <li *ngIf="(globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3 || globals.institute_details.role_value == 6)"
                                        [ngClass]="{'active':globals.currentLink=='/manage/assigned-user-subscription/list'}">
                                        <a routerLink="/manage/assigned-user-subscription/list">List</a>
                                    </li>
                                    <li *ngIf="(globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3 || globals.institute_details.role_value == 6)"
                                        [ngClass]="{'active':globals.currentLink=='/manage/assign-user-subscription'}">
                                        <a routerLink="/manage/assign-user-subscription">Assign</a>
                                    </li>
                                    <!-- <li *ngIf="(globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)"
                                [ngClass]="{'active':globals.currentLink=='/manage/subscription/list'}">
                                <a routerLink="/manage/subscription/list">Subscription Packs</a>
                            </li> -->

                                </ul>
                            </li>

                    </div>
            </div>
            </li>

            <li *ngIf="globals.institute_details.role_value != 6 && 
            (globals.institute_details && 
            (globals.institute_details.role_value == 1 
            || globals.institute_details.role_value == 2 
            || globals.institute_details.role_value == 3 
            || globals.institute_details.role_value == 6)) 
            && (globals.permissions['ownership-list'] 
            || globals.permissions['type-list'] 
            || globals.permissions['unit-list'] 
            || globals.permissions['system-list'] 
            || globals.permissions['department-list'])" [ngClass]="{'active':
                (globals.currentLink.split('/')[2]=='organization-ownership' 
                || globals.currentLink.split('/')[2]=='organization-type' 
                || globals.currentLink.split('/')[2]=='division' 
                || globals.currentLink.split('/')[2]=='organization-system' 
                || globals.currentLink.split('/')[2]=='unit' 
                || globals.currentLink.split('/')[2]=='subscription-department' 
                || globals.currentLink.split('/')[2]=='coupon'
                || globals.currentLink.split('/')[2]=='unique-users'
                || globals.currentLink.split('/')[2]=='group')}">
                <a>Manage <i class="fa fa-caret-down"></i></a>
                <div class="submenu dropdown-menu">

            <li *ngIf="globals.permissions['invite-user-list'] && 
            (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)"
                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage')}">
                <a (click)="backToOrg(false)" routerLink="/manage/unique-users">Unique Users</a>
            </li>

            <div class="width_menu">
                <li *ngIf="globals.permissions['department-list']"
                    [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='division')}">
                    <span class="title">Divisions</span>
                    <ul class="submenu dropdown-menu">
                        <li [ngClass]="{'active':globals.currentLink=='/manage/division/list'}">
                            <a (click)="backToOrg(false)" routerLink="/manage/division/list">List</a>
                        </li>
                        <li [ngClass]="{'active':globals.currentLink=='/manage/subscription-department/list'}">
                            <a routerLink="/manage/subscription-department/list">Subscriptions</a>
                        </li>
                    </ul>
                </li>
                <!-- <li *ngIf="globals.permissions['unit-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='unit')}">
                <a routerLink="/manage/unit/list">Units</a>
            </li> -->
                <!-- *ngIf="globals.permissions['ownership-list'] || globals.permissions['system-list'] || globals.permissions['type-list']" -->

                <li *ngIf="globals.permissions['coupon-list']">
                    <span class="title">Administration</span>
                    <!-- {{globals.permissions | json}} -->
                    <!-- *ngIf="globals.permissions['coupon-list']" -->
                    <ul class="submenu dropdown-menu">
                        <li *ngIf="globals.permissions['coupon-list']"
                            [ngClass]="{'active':globals.currentLink=='/manage/coupon/list'}">
                            <a routerLink="/manage/coupon/list">Coupons</a>
                        </li>
                        <!-- <li *ngIf="globals.permissions['ownership-list']"
                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-ownership')}">
                        <a routerLink="/manage/organization-ownership/list">Ownerships</a>
                    </li>
                    <li *ngIf="globals.permissions['system-list']"
                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-system')}">
                        <a routerLink="/manage/organization-system/list">Systems</a>
                    </li>
                    <li *ngIf="globals.permissions['type-list']"
                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-type')}">
                        <a routerLink="/manage/organization-type/list">Types</a>
                    </li> -->
                    </ul>
                </li>
            </div>
        </div>
        </li>

        <li *ngIf="(globals.institute_details && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3 || globals.institute_details.role_value == 6)) && (globals.permissions['emailtemplate-list'] || globals.permissions['permission-list'])"
            [ngClass]="{'active':(globals.currentLink.split('/')[2]=='email-template' || globals.currentLink=='/manage/role-permission')}">
            <a>Configuration <i class="fa fa-caret-down"></i></a>
            <div class="submenu dropdown-menu">
                <div class="width_menu">
        <li *ngIf="globals.permissions['emailtemplate-list']"
            [ngClass]="{'active': (globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='email-template')}">
            <a routerLink="/manage/email-template/list">Email
                Templates</a>
        </li>
        <li *ngIf="globals.permissions['permission-list']"
            [ngClass]="{'active': globals.currentLink=='/manage/role-permission'}">
            <a routerLink="/manage/role-permission">Role
                Permission</a>
        </li>
    </div>
</div>
</li>

<!-- <li [ngClass]="{'active':(globals.currentLink.split('/')[1]=='organization-registration')}">
    <a routerLink="/organization-registration">Register New</a>
</li> -->
<!-- <li *ngIf="(globals.institute_details && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3)) && (globals.permissions['permission-list']) || (globals.permissions['institute-list'] || globals.permissions['group-list'] || globals.permissions['add-invite-user'] || globals.permissions['invite-user-list'] || globals.permissions['configuration-list'])"
                                [ngClass]="{'active':globals.currentModule=='manage' || globals.currentLink.split('/')[1]=='organization-registration'}">
                                <a>Manage <i class="fa fa-caret-down ms-2"></i></a>
                                <div class="submenu dropdown-menu">
                                    <div class="width_menu" *ngIf="globals.permissions['permission-list']">
                                        <ul>
                                            <li><span class="title">General</span>
                                                <ul class="submenu dropdown-menu">
                                                    <li *ngIf="globals.permissions['emailtemplate-list']"
                                                        [ngClass]="{'active': (globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='email-template')}">
                                                        <a routerLink="/manage/email-template/list">Email
                                                            Templates</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['permission-list']"
                                                        [ngClass]="{'active': globals.currentLink=='/manage/role-permission'}">
                                                        <a routerLink="/manage/role-permission">Role
                                                            Permission</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="width_menu"
                                        *ngIf="globals.permissions['institute-list'] || globals.permissions['department-list'] || globals.permissions['group-list'] || globals.permissions['add-invite-user'] || globals.permissions['invite-user-list'] || (globals.permissions['ownership-list'] || globals.permissions['type-list']))">
                                        <ul>
                                            <li><span class="title">Organization</span>
                                                <ul class="submenu dropdown-menu">
                                                    <li 
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='organization-registration')}">
                                                        <a routerLink="/organization-registration">Register New</a>
                                                    </li>
                                                    <li *ngIf="(globals.institute_details && globals.institute_details.institute && globals.institute_details.institute.id!='')"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization' && globals.currentLink.split('/')[3]=='update')}">
                                                        <a routerLink="/manage/organization/update">Update Info</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['add-invite-user']"
                                                        [ngClass]="{'active':(globals.currentLink=='/manage/user/connect')}">
                                                        <a routerLink="/manage/user/connect">Connect a User</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['invite-user-list']"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && ( globals.currentLink.split('/')[2]=='users' || (globals.currentLink.split('/')[2]=='user' && globals.currentLink.split('/')[3]=='edit')))}">
                                                        <a routerLink="/manage/users"></a>
                                                    </li>
                                                    <li *ngIf="globals.institute_details && globals.institute_details.role_value == 1"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='user' && globals.currentLink.split('/')[3]=='connection-history')}">
                                                        <a routerLink="/manage/user/connection-history">User Connection History</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['department-list']"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='division')}">
                                                        <a routerLink="/manage/division/list">Divisions</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['group-list']"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='group')}">
                                                        <a routerLink="/manage/group/list">Groups</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['institute-list']"
                                                        [ngClass]="{'active':globals.currentLink=='/manage/organization/list'}">
                                                        <a routerLink="/manage/organization/list">List</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['ownership-list']"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-ownership')}">
                                                        <a routerLink="/manage/organization-ownership/list">Ownerships</a>
                                                    </li>
                                                    <li *ngIf="globals.permissions['type-list']"
                                                        [ngClass]="{'active':(globals.currentLink.split('/')[1]=='manage' && globals.currentLink.split('/')[2]=='organization-type')}">
                                                        <a routerLink="/manage/organization-type/list">Types</a>
                                                    </li>                                                    
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li> -->

<!-- <li *ngIf="globals.permissions['configuration-list']"
                                [ngClass]="{'active':(globals.currentLink.split('/')[1]=='configuration')}">
                                <a>Configuration <i class="fa fa-caret-down ms-2"></i></a>
                                <div class="submenu dropdown-menu">
                                    <div class="width_menu">
                                        <ul>
                                            <li><span class="title">Statuses</span>
                                                <ul class="submenu dropdown-menu">
                                                    <li
                                                        [ngClass]="{'active':(globals.currentLink=='/configuration/user-status')}">
                                                        <a routerLink="/configuration/user-status">User</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <--- <div class="width_menu">
                                        <ul>
                                            <li><span class="title">General</span>
                                                <ul class="submenu dropdown-menu">
                                                    <li
                                                        [ngClass]="{'active':(globals.currentLink=='/configuration/notification-preferences')}">
                                                        <a routerLink="/configuration/notification-preferences">Notification
                                                            Preferences</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div> ->

                                </div>
                            </li> -->
</ul>

</div>

<!-- <div *ngIf="globals.institute_details.role_value != 6" class="products_menu"><span class="product_text">My Products <i -->
<div class="products_menu"><span class="product_text">My Products <i class="fa fa-caret-down ms-1"></i></span>
    <div class="dropdown-menu" kendoTooltip position="left">
        <!-- <div class="product_half">
            <h4>As an Individual</h4>
            <ul>
                <li>
                    <a href="{{globals.covidUrl}}"><img src="{{globals.cndURL}}assets/images/covid.png" alt=""
                            class="img-fluid" /><span>COVID</span>
                    </a>
                    <div class="clearfix"></div>
                </li>
                <li *ngIf="dcm_present">
                    <a (click)="viewIndividualDCM()"><img src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                            class="img-fluid" /><span>Self Assessment Tool</span>
                    </a>
                    <div class="clearfix"></div>
                </li>
            </ul>
        </div> -->
        <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
        target="_blank">Buy Licenses</a></button> -->
        <div class="product_half">
            <!-- <h4>As an Organization</h4> -->
            <ul>
                <li class="active" *ngIf="connector_present">
                    <img src="{{globals.cndURL}}assets/images/connector.png" alt=""
                        class="img-fluid" /><span>Connector</span>
                    <div class="clearfix"></div>
                </li>

                <li >
                    <a (click)="viewSAT()"><img src="{{globals.cndURL}}assets/images/sat.png" alt=""
                            class="img-fluid" /><span>Self-Assessment Tool</span>
                    </a>
                    <div class="clearfix"></div>
                </li>
                <!-- <li *ngIf="!connector_present">
                    <a (click)="registerOrg()"><img src="{{globals.cndURL}}assets/images/connector.png" alt=""
                            class="img-fluid" /><span>Register your Organization</span>
                    </a>
                    <div class="clearfix"></div>
                </li> -->
                <!-- <li *ngIf="org_dcm_present">
                    <a (click)="viewOrgDCM()"><img src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                            class="img-fluid" /><span>Self Assessment Tool</span>
                    </a>
                    <div class="clearfix"></div>
                </li> -->
                <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                target="_blank">Buy Licenses</a></button> -->
                <li *ngIf="administrator_present && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2 || globals.institute_details.role_value == 3 || globals.institute_details.role_value == 6)">
                    <a (click)="viewAdmin()"><img src="{{globals.cndURL}}assets/images/crm.png" alt=""
                            class="img-fluid" /><span>Administration Tool</span>
                    </a>
                    <div class="clearfix"></div>
                </li>

                

            </ul>
        </div>
        <div class="clearfix"></div>
        <!-- <div class="links_block"> <a href="https://faqs.ifma.uatbyopeneyes.com/home" class="feedback_a" target="_blank">FAQs</a>
            <a href="#" class="feedback_a" target="_blank">Share a Feedback</a></div> -->
    </div>
</div>
<!-- <div class="products_menu"><span class="product_text">My Products <i class="fa fa-caret-down ms-1"></i></span>
    <div class="dropdown-menu" kendoTooltip>
        <ul>
            <li>
                <img src="{{globals.cndURL}}assets/images/covid.png" alt="" class="img-fluid" /><span>COVID</span>
                <a href="{{globals.covidUrl}}" target="_blank">View</a>
            </li>
            <li *ngIf="dcm_present"> <img src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                    class="img-fluid" /><span>Digital Competency Framework</span>
                <a href="{{globals.dcmUrl}}" target="_blank">View</a>
                <button class="buy_lic_btn" kendoButton>
                    <a href="https://www.amsn.org/oclp" target="_blank">Add more Licenses</a></button>
                <div class="clearfix"></div>
            </li>
            <li class="active" *ngIf="connector_present">
                <img src="{{globals.cndURL}}assets/images/connector.png" alt=""
                    class="img-fluid" /><span>Connector</span>
            </li>
            <li *ngIf="administrator_present">
                <img src="{{globals.cndURL}}assets/images/administrator.png" alt=""
                    class="img-fluid" /><span>Adminstration</span>
                <a href="{{globals.adminUrl}}" target="_blank">View</a>
            </li>
        </ul>
        <div class="clearfix"></div>
        <ng-container *ngIf="!dcm_present || !connector_present">
            <hr />
            <p><b>Might be Interested in</b></p>
            <ul>
                <li *ngIf="!connector_present">
                    <img src="{{globals.cndURL}}assets/images/connector.png" alt=""
                        class="img-fluid" /><span>Connector</span>
                </li>
                <li *ngIf="!dcm_present">
                    <img src="{{globals.cndURL}}assets/images/dcm.png" alt="" class="img-fluid" /><span>Digital
                        Competency Model</span>
                    <button class="buy_lic_btn no_border"><a href="https://www.amsn.org/oclp" target="_blank">Buy
                            Now</a></button>
                    <div class="clearfix"></div>
                </li>
            </ul>
        </ng-container>
    </div>
</div> -->
</nav>
</div>
</div>
</div>
</div>