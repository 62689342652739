import { Component, input, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { CouponService } from '../services/coupon.service';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
declare var Swal: any;

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrl: './coupon-list.component.css'
})
export class CouponListComponent {


  couponList: { total: any; data: any; };

  pageSize: number = 10;
  allowUnsort = true;
  skip: number = 0;
  paginationEntity = {
    limit: this.pageSize,
    offset: 0,
    search_text:'',
    // searchData: {
    //   status: '',
    //   searchQuery: ''
    // },
    sortOrder: [{
      // field: "display_text",
      // dir: 'asc'
      field: 'created_at',
      dir: 'desc',
    }]
  };
  state = {
    skip: 0,
    take: this.pageSize
  };
  sort: SortDescriptor[] = [{
    field: 'created_at',
      dir: 'desc',
  }];
  gridLoading:boolean = false;
  discountType: any;

  constructor(
    public globals: Globals, 
    private router: Router, 
    private route: ActivatedRoute, 
    private couponService: CouponService
  ) { }

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Manage','Types',''];
    this.gridLoading = true;
    this.getData();
  }

 


  getData() {
    let coupon_type_id = this.discountType;
    let entity = coupon_type_id
    this.couponService.getAll(this.paginationEntity, entity,)
      .then((data: any) => {
        this.couponList = {
          data: (data.count > 0) ? orderBy(data.data, this.sort) : '',
          total: data.count,
        }

        // this.couponList = {
        //   data: orderBy(data.data, this.sort),
        //   total: 2,
        // }
        this.gridLoading = false;
      },
      (error) => {
        this.gridLoading = false;
        this.skip = this.skip > this.couponList.total ? 0 : this.skip;
        this.globals.errorSweetAlert();
      });
  }

  // updateStatus(dataItem,index) {
  //   let text; let ctext;
  //   if(this.couponList.data[index].is_active==true){
  //       text = 'activate';
  //       ctext = 'Activate';
  //   } else {
  //       text = 'deactivate';
  //       ctext = 'Deactivate';
  //   }
  //   Swal.fire({
  //       icon: 'warning',
  //       title: ctext+' Type' + ' - ' + dataItem.display_text,
  //       text: 'Are you sure, you want to '+text+' this type?', 
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: "No"
  //   })
  //   .then((result) => {
  //     if (result.isConfirmed) {
  //       this.gridLoading = true;
  //       this.ConfigurationService.updateStatus(dataItem.id)
  //       .then((data) => {
  //         this.gridLoading = false;
  //         this.globals.sweetAlert('success', 'Type '+ctext+'d', 'Type '+text+'d successfully.');
  //       },
  //       (error) => {
  //         this.gridLoading = false;
  //         this.globals.errorSweetAlert();
  //       });
  //     } else {
  //         this.couponList.data[index].is_active = !this.couponList.data[index].is_active;
  //     }
  //   })
  // }

  delete(dataItem) {
    Swal.fire({
      icon: 'warning',
      title: 'Delete Coupon' + ' - ' + dataItem.name,
      text: 'Are you sure you want to delete this coupon?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.gridLoading = true;
        this.couponService.delete(dataItem.id)
          .then((data) => {
            this.gridLoading = false;
            this.getData();
            this.globals.sweetAlert('success','Coupon Deleted','Coupon has been deleted successfully.');
          },
          (error) => {
            this.gridLoading = false;
            this.globals.errorSweetAlert();
          });
      }
    })
  }

  edit(id) {
    this.router.navigate(['/manage/coupon/edit/' + window.btoa(id)]);
  }

  // pageChange Event
  public pageChange(event: PageChangeEvent): void {
    this.skip = (this.pageSize == event.take) ? event.skip : ((this.skip < event.take) ? event.skip : this.skip);
    this.pageSize = event.take;
    this.paginationEntity.offset = Math.floor((this.skip / this.pageSize));
    this.paginationEntity.limit = event.take;
    this.getData();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != "undefined") {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getData();
    }
  }

  // Filter event
  public onFilter(inputValue: string): void {
    if (inputValue != "" && inputValue.length>2) {
      this.paginationEntity.offset = 0;
      this.paginationEntity.search_text = inputValue;
      // this.paginationEntity.searchData.searchQuery = inputValue;
      this.getData();
    }  else if(inputValue == "") {
      // this.paginationEntity.searchData.searchQuery = '';
      this.paginationEntity.search_text='';
      this.pageChange(this.state);
    }
  }

}

