import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private globals: Globals) { }

  getPersonalInfo() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/user')
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  updatePersonalInfo(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/update-profile', entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getInstituteInfo(id) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/institute/edit/' + id)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateInstituteInfo(entity, id) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/update-institute/' + id, entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updatePassword(entity, id) {
    let promise = new Promise((resolve, reject) => { resolve(entity); });
    return promise;
  }
}
