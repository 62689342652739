import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Page Not Found','',''];
  }

}
