import { Injectable } from '@angular/core';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IntermediatePageService {

  constructor(private http: HttpClient, private globals: Globals,private CookieService: CookieService, private router: Router) { }

  netFormAuthToken(postdata) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/authToken', postdata)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  netFormGetIndividualInfo(postdata) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/get-individualInfo', postdata)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }


}
