import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var $, Swal: any;
import { CookieService } from 'ngx-cookie-service';
import { IntermediatePageService } from '../services/intermediate-page.service';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
})
export class IntermediatePageComponent implements OnInit {
  constructor(
    public globals: Globals,
    private router: Router,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private CookieService: CookieService,
    private IntermediatePageService: IntermediatePageService
  ) {}

  ngOnInit(): void {
    this.globals.isLoading = true;

    let u = this.router.url.split('/')[3];
    if (u) {
      var ur = u.split('?');
      if (ur[0] == 'organization-registration') {
        localStorage.setItem("registration", 'yes');
      }
    }

    // let token = this.route.snapshot.paramMap.get('token')
    //   ? this.route.snapshot.paramMap.get('token')
    //   : null;
    let token = this.route.snapshot.queryParams.IfmaToken
      ?this.route.snapshot.queryParams.IfmaToken
      :null;
    this.CookieService.set(
      'amsn_token',
      token,
      365,
      '/',
      this.globals.CookieDomainUrl
    );

    this.AuthService.checkAMSNLogin({
      usertoken: token,
      ip_address: null,
      user_agent: null,
    }).then(
      (data) => {

        this.AuthService.getAuthUser().then(
          (data) => {
            //this.globals.isLoading = false;
            // if (this.globals.authData.list.length > 1) {
            //   let check = 0;
            //   let inactive_check = 0;
            //   let total_check = 0;
            //   let active_check = 0;
            //   let index = 0;
            //   for (let i = 0; i < this.globals.authData.list.length; i++) {
            //     if (
            //       this.globals.authData.list[i].role_value == 1 ||
            //       this.globals.authData.list[i].role_value == 2
            //     ) {
            //       if (
            //         this.globals.authData.list[i].institute != '' &&
            //         this.globals.authData.list[i].institute.is_active == 0
            //       ) {
            //       } else {
            //         check++;
            //         this.globals.authData.list[i].index_number = i;
            //         this.CookieService.set(
            //           'institute_details',
            //           window.btoa(
            //             JSON.stringify(this.globals.authData.list[i])
            //           ),
            //           365,
            //           '/',
            //           this.globals.CookieDomainUrl
            //         );
            //         this.globals.institute_details =
            //           this.globals.authData.list[i];
            //         if (
            //           this.globals.institute_details.role_value == 1 ||
            //           this.globals.institute_details.role_value == 2
            //         ) {
            //           window.location.href =
            //             this.globals.baseUrl + '/overview-dashboard';
            //         } else {
            //           this.globals.selected_institute =
            //             this.globals.institute_details.institute;
            //           this.CookieService.set(
            //             'selected_institute',
            //             window.btoa(
            //               JSON.stringify(
            //                 this.globals.institute_details.institute
            //               )
            //             ),
            //             365,
            //             '/',
            //             this.globals.CookieDomainUrl
            //           );
            //           this.globals.saveRecentlyInstitutes(
            //             this.globals.institute_details.institute.id
            //           );
                     
            //           window.location.href =
            //             this.globals.baseUrl + '/dashboard';
            //         }
            //         //break;
            //       }
            //     }
            //     if (this.globals.authData.list[i].institute != '') {
            //       if (this.globals.authData.list[i].institute.is_active == 0) {
            //         inactive_check++;
            //       } else {
            //         index = i;
            //         active_check++;
            //       }
            //       total_check++;
            //     }
            //   }
            //   if (
            //     active_check == 1 &&
            //     total_check == this.globals.authData.list.length
            //   ) {
            //     let i = index;
            //     this.globals.authData.list[i].index_number = i;
            //     this.CookieService.set(
            //       'institute_details',
            //       window.btoa(JSON.stringify(this.globals.authData.list[i])),
            //       365,
            //       '/',
            //       this.globals.CookieDomainUrl
            //     );
            //     this.globals.institute_details = this.globals.authData.list[i];
            //     if (
            //       this.globals.institute_details.role_value == 1 ||
            //       this.globals.institute_details.role_value == 2
            //     ) {
            //       window.location.href =
            //         this.globals.baseUrl + '/overview-dashboard';
            //     } else {
            //       this.globals.selected_institute =
            //         this.globals.institute_details.institute;
            //       this.CookieService.set(
            //         'selected_institute',
            //         window.btoa(
            //           JSON.stringify(this.globals.institute_details.institute)
            //         ),
            //         365,
            //         '/',
            //         this.globals.CookieDomainUrl
            //       );
            //       this.globals.saveRecentlyInstitutes(
            //         this.globals.institute_details.institute.id
            //       );
 
            //       if(this.globals.institute_details.role_value == 6){
            //         window.location.href = this.globals.baseUrl + '/manage/assigned-user-subscription/list';

            //         return;
            //       }
            //       window.location.href = this.globals.baseUrl + '/dashboard';
            //     }
            //   } else {
            //     console.log('inter-166');
            //     if (inactive_check == total_check) {
            //       this.router.navigate(['/landing-dashboard']);
            //       // this.AuthService.logout()
            //       // .then((data) => {
            //       //   this.globals.isLoading = false;
            //       //   window.location.href = this.globals.amsnLogoutUrl;
            //       // },
            //       // (error) => {
            //       //   this.globals.isLoading = false;
            //       //   this.globals.errorSweetAlert();
            //       // });
            //     } else if (check == 0) {
            //       let i = index;
            //       this.globals.authData.list[i].index_number = i;
            //       this.CookieService.set(
            //         'institute_details',
            //         window.btoa(JSON.stringify(this.globals.authData.list[i])),
            //         365,
            //         '/',
            //         this.globals.CookieDomainUrl
            //       );
            //       this.globals.institute_details =
            //         this.globals.authData.list[i];
            //       if (
            //         this.globals.institute_details.role_value == 1 ||
            //         this.globals.institute_details.role_value == 2
            //       ) {
            //         window.location.href =
            //           this.globals.baseUrl + '/overview-dashboard';
            //       } else {
            //         this.globals.selected_institute =
            //           this.globals.institute_details.institute;
            //         this.CookieService.set(
            //           'selected_institute',
            //           window.btoa(
            //             JSON.stringify(this.globals.institute_details.institute)
            //           ),
            //           365,
            //           '/',
            //           this.globals.CookieDomainUrl
            //         );
            //         this.globals.saveRecentlyInstitutes(
            //           this.globals.institute_details.institute.id
            //         );
                   
                  
            //       if(this.globals.institute_details.role_value == 6){
            //         window.location.href = this.globals.baseUrl + '/manage/assigned-user-subscription/list';

            //         return;
            //       }
            //       window.location.href = this.globals.baseUrl + '/dashboard';
            //       }
            //       // this.globals.institute_details = null;
            //       // this.CookieService.delete('institute_details', '/');
            //       // this.router.navigate(['/landing-dashboard']);
            //     }
            //   }
            // } else if (this.globals.authData.list.length == 1) {
            //   if (
            //     this.globals.authData.list[0].institute != '' &&
            //     this.globals.authData.list[0].institute.is_active == 0
            //   ) {
            //     console.log('inter-233');
            //     this.router.navigate(['/landing-dashboard']);
            //     // this.AuthService.logout()
            //     // .then((data) => {
            //     //   this.globals.isLoading = false;
            //     //   window.location.href = this.globals.amsnLogoutUrl;
            //     // },
            //     // (error) => {
            //     //   this.globals.isLoading = false;
            //     //   this.globals.errorSweetAlert();
            //     // });
            //   } else {
            //     this.globals.authData.list[0].index_number = 0;
            //     this.CookieService.set(
            //       'institute_details',
            //       window.btoa(JSON.stringify(this.globals.authData.list[0])),
            //       365,
            //       '/',
            //       this.globals.CookieDomainUrl
            //     );
            //     this.globals.institute_details = this.globals.authData.list[0];
            //     if (
            //       this.globals.institute_details.role_value == 1 ||
            //       this.globals.institute_details.role_value == 2
            //     ) {
            //       window.location.href =
            //         this.globals.baseUrl + '/overview-dashboard';
            //     } else {
            //       this.globals.selected_institute =
            //         this.globals.institute_details.institute;
            //       this.CookieService.set(
            //         'selected_institute',
            //         window.btoa(
            //           JSON.stringify(this.globals.institute_details.institute)
            //         ),
            //         365,
            //         '/',
            //         this.globals.CookieDomainUrl
            //       );
            //       this.globals.saveRecentlyInstitutes(
            //         this.globals.institute_details.institute.id
            //       );
                 
            //       //here redirecting to div head

            //       if(this.globals.institute_details.role_value == 6){
            //         window.location.href = this.globals.baseUrl + '/manage/assigned-user-subscription/list';

            //         return;
            //       }
            //       window.location.href = this.globals.baseUrl + '/dashboard';
            //     }
            //   }
            // } else if (this.globals.authData.list.length == 0) {
            //   console.log('inter-269');
            //   this.router.navigate(['/landing-dashboard']);
            //   // this.AuthService.logout()
            //   // .then((data) => {
            //   //   this.globals.isLoading = false;
            //   //   window.location.href = this.globals.amsnLogoutUrl;
            //   // },
            //   // (error) => {
            //   //   this.globals.isLoading = false;
            //   //   this.globals.errorSweetAlert();
            //   // });
            // }
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          }
        );
      },
      (error) => {
        this.globals.isLoading = false;
        if (error.status == 400) {
          this.globals.sweetAlert(
            'warning',
            'Deactive',
            'Your account is deactive. so you cannot login.'
          );
        } else if (error.status == 401) {
          this.globals.sweetAlert(
            'warning',
            'Invalid Login Credentials',
            'Either email or password is incorrect.'
          );
        }else if(error.status == 403){
          // for deactivated cohort login
        Swal.fire({
          title: 'Organization is disconnected!',
          text: 'To reconnect the organization. Please contact the IFMA admin.',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = this.globals.amsnLogoutUrl;
          }
        });
        return;
        }
        
        else {
          this.globals.errorSweetAlert();
        }
      }
    );
  }
}
