import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getCounts(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(
          this.globals.baseAPIUrl + '/connector/dashboard/countData',
          entity
        )
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTopDivisionsAndUnits(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/connector/dashboard/topData', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUserData(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/connector/dashboard/userData', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getCountForAdmin(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(
          this.globals.baseAPIUrl + '/connector/admin/dashboard/count-data',
          entity
        )
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  // getOrganizationByOwnershipTypeForAdmin(entity) {
  //   let promise = new Promise((resolve, reject) => {
  //     this.http
  //       .post(
  //         this.globals.baseAPIUrl +
  //           '/connector/admin/dashboard/organizations-by-data',
  //         entity
  //       )
  //       .toPromise()
  //       .then(
  //         (res) => {
  //           // Success
  //           resolve(res);
  //         },
  //         (msg) => {
  //           // Error
  //           reject(msg);
  //         }
  //       );
  //   });
  //   return promise;
  // }

  // getTopOrganizationForAdmin(entity) {
  //   let promise = new Promise((resolve, reject) => {
  //     this.http
  //       .post(
  //         this.globals.baseAPIUrl +
  //           '/connector/admin/dashboard/top-organization',
  //         entity
  //       )
  //       .toPromise()
  //       .then(
  //         (res) => {
  //           // Success
  //           resolve(res);
  //         },
  //         (msg) => {
  //           // Error
  //           reject(msg);
  //         }
  //       );
  //   });
  //   return promise;
  // }

  getOrganizationsBySystemForAdmin(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(
          this.globals.baseAPIUrl +
            '/connector/admin/dashboard/organizations-by-system',
          entity
        )
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getStatewiseCountForAdmin(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(
          this.globals.baseAPIUrl + '/connector/admin/dashboard/state-wise',
          entity
        )
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  refreshLicensePack(token) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/license/org-refresh-license', token)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
