import { NgModule } from '@angular/core';

// helpers
import { PhoneMaskDirective } from '../phone-mask.directive';
import { PhonePipe } from '../_helpers/phone.pipe';

import { CookieService } from 'ngx-cookie-service';

@NgModule({
  exports: [PhoneMaskDirective, PhonePipe],
  declarations: [PhoneMaskDirective, PhonePipe],
  providers: [PhonePipe, CookieService],
})
export class SharedModule {}