import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/globals';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient, private globals: Globals) { }

  add(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/add', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  revokeSubscription(entity) {
  
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/revoke-subscription', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  reassignSubscription(entity) {
  
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/reassign-subscription', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUserByInstitute(entity){
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/user-by-institute', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUserByDept(entity){
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/user-subscription-by-department', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  update(entity, id) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/edit/' + id, entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getUserForDropdown(entity){
    

    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/users-dropdown', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  getUserListForSub(entity){
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/unassigned-users-subscriptions-list', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateDeptSubs(entity, id) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/edit-division-subscription/' + id, entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getDepartments(id:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + "/department/getDepartment/"+id)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  getDepartmentsByUser(id:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + "/department/getDepartmentByHead/"+id)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  assignUserSub(entity){
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/assign-user' ,entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  assignUserSubByDept(entity){
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/assign-user-by-department ' ,entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getLicenses(entity){
  
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/subscriptions-list ', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;

  }


  assign(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/subscriptions/assign-department', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAll(entity:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + "/subscriptions/get-all", entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllSubDeptList(entity:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + "/subscriptions/department-by-institute", entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getByDeptId(id){
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/subscriptions/get-department-subscription-by-id/' + id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  } 

  changeStatusDeptSubscription(id){
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/subscriptions/change-status-department-subscription/' + id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getById(id) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/subscriptions/get-by-id/' + id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getConfigValueByKey(key: string) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/configuration/get-value-by-key/'+key)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  changeSubscriptionStatus(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/subscriptions/changeStatus/'+id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  renewSubs(entity:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + "/dcm/extenstion/admin", entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  renewSubsByExpiryDays(entity:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + "/configurations", entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


}
