<div class="connector_frontend_block">
  <div class="loader_block" *ngIf="globals.isLoading" >
    <div class="height_loader">
      <div class="img_loader">
        <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
  <app-header *ngIf="globals.authData!=null && globals.currentLink!='/landing-dashboard'"></app-header>
  <app-side-menu *ngIf="globals.authData!=undefined && globals.currentLink!='/landing-dashboard'"></app-side-menu>
  <app-breadcrumbs *ngIf="(globals.currentLink != '/manage/unique-users' && globals.currentLink != '/manage/coupon/list' && globals.currentLink != '/manage/coupon/add') && globals.authData!=undefined && globals.currentLink!='/landing-dashboard' && globals.institute_details!=null && globals.currentLink!= '/manage/organization/add'"></app-breadcrumbs>
  <router-outlet></router-outlet>
  <app-footer *ngIf="globals.authData!=null && globals.currentLink!='/landing-dashboard'"></app-footer>
</div>