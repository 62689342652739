import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InstituteUsersService } from '../app-manage/services/institute-users.service';
import { ConfigurationService } from '../app-manage/services/configuration.service';
import { SystemService } from '../app-manage/services/system.service';
declare var Swal: any;
import { CookieService } from 'ngx-cookie-service';
//import { $ } from 'protractor';
declare var $, Swal: any, _;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .hide-icon.k-dialog-wrapper .k-dialog-titlebar .k-dialog-actions {
        display: none;
      }
    `,
  ],
})
export class BreadcrumbsComponent implements OnInit {
  id;
  departmentForm: UntypedFormGroup;
  institute_id =
    this.globals.selected_institute != null
      ? this.globals.selected_institute.id
      : null;
  instituteList = [];
  instituteListFilterData = [];
  Recently_instituteList = [];
  OESS_Admin_present: boolean = false;
  AMSN_Admin_present: boolean = false;
  Individual_present: boolean = false;
  orgError: boolean = false;
  selected_institute =
    this.globals.selected_institute != null
      ? this.globals.selected_institute
      : null;
  btnDisabled = false;

  existingPrimaryContact: any = {};
  userList = [];
  userListFilterData = [];
  roleList: any;
  roleListFilterData: any;
  primary_user_id = null;
  primaryContactError: boolean = false;
  primaryContactRoleError: boolean = false;

  instituteOwnershipList = [];
  instituteOwnershipListFilterData: any;
  instituteTypeList = [];
  instituteTypeListFilterData: any;
  systemList = [];
  systemListFilterData: any;

  is_other_system: boolean = false;
  system_already_in_list: boolean = false;

  invitationForm: UntypedFormGroup;
  existing_user_role_id = null;
  fein_number_errors = {
    pattern: false,
    required: false,
  };
  fein_number: any = '';
  institute_ownership_id = '';
  institute_type_id = '';
  institute_system_id = '';
  institute_system_value = '';
  institute_other_system = '';

  isSwitchingOrgActive : any;

  other_system_errors = {
    minlength: false,
    required: false,
  };

  constructor(
    public globals: Globals,
    public router: Router,
    private CookieService: CookieService,
    private InstituteUsersService: InstituteUsersService,
    private ConfigurationService: ConfigurationService,
    private formBuilder: UntypedFormBuilder,
    public SystemService: SystemService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getRoles();

    if (this.institute_id) {
      this.getInstituteAdmin();
    }

    if (
      this.institute_id &&
      this.globals.institute_details.role_value != 2 &&
      this.globals.institute_details.role_value != 1
    ) {
      if (this.globals.institute_details) {
        if (
          !this.selected_institute.primary_contact_id ||
          (!this.selected_institute.fein_number &&
            this.selected_institute.fein_attempt == 0)
          // (this.selected_institute.institute_types == '' && this.selected_institute.institute_types_attempt == 0) ||
          // (this.selected_institute.institute_owners == '' && this.selected_institute.institute_owners_attempt == 0) ||
          // (this.selected_institute.institute_systems == '' && this.selected_institute.institute_systems_attempt == 0)
        ) {
          this.getConfigurationData();
          this.getSystems();
          this.globals.selectPrimaryContactModel = true;
        }
      }
    }
    // else if(this.institute_id &&
    //   this.globals.institute_details.role_value == 1 || this.globals.institute_details.role_value == 2){
    //     this.filterData(this.selected_institute);
    // }

    this.invitationForm = this.formBuilder.group({
      institute_id: [this.institute_id, [Validators.required]],
    });

    if (
      this.globals.institute_details.role_value == 2 ||
      this.globals.institute_details.role_value == 1
    ) {
      this.getInstitutes();
      if (this.globals.Recently_institutes.length > 0) {
        for (let i = 0; i < this.globals.Recently_institutes.length; i++) {
          this.getInstituteDetailsById(this.globals.Recently_institutes[i], i);
        }
      }
    } else {
      let attempt = {
        fein_attempt: this.selected_institute.fein_attempt,
        institute_owners_attempt:
          this.selected_institute.institute_owners_attempt,
        institute_types_attempt:
          this.selected_institute.institute_types_attempt,
        primary_contact_attempt:
          this.selected_institute.primary_contact_attempt,
      };

      let OESS_Admin_present = false;
      let AMSN_Admin_present = false;
      let Individual_present = false;
      let instituteList = [];
      let instituteList_rec = [];
      this.globals.authData.list.forEach(function (s) {
        if (s.role_value == 1) {
          OESS_Admin_present = true;
        } else if (s.role_value == 2) {
          OESS_Admin_present = true;
        } else if (s.role_value == 5) {
          Individual_present = true;
        }
        if (s.institute != null && s.institute != '' && s.role_value != 4) {
          instituteList.push(s);
          instituteList_rec.push(s.institute.id);
        }
      });

      this.OESS_Admin_present = OESS_Admin_present;
      this.AMSN_Admin_present = AMSN_Admin_present;
      this.Individual_present = Individual_present;

      let instituteList1 = [];
      instituteList.forEach(function (obj) {
        let data = {
          id: obj.institute.id,
          institute_name: obj.institute.institute_name,
        };
        instituteList1.push(data);
      });
      instituteList1.unshift({
        id: null,
        institute_name: 'Select Organization',
        is_active: 1,
        provider_id: '',
      });
      this.instituteList = instituteList1;
      this.instituteListFilterData = instituteList1;

      let Recently_instituteList_ids = [];
      let Recently_institutes = this.globals.Recently_institutes;
      Recently_institutes.forEach(function (s) {
        let index = $.inArray(s, instituteList_rec);
        if (index !== -1) {
          Recently_instituteList_ids.push(s);
          instituteList_rec.splice(index, 1);
        }
      });
      let end = 3 - Recently_instituteList_ids.length;
      if (Recently_instituteList_ids.length < 3) {
        for (let i = 0; i < end; i++) {
          if (instituteList_rec.length > i) {
            Recently_instituteList_ids.push(instituteList_rec[i]);
          }
        }
      }
      if (Recently_instituteList_ids.length > 0) {
        for (let i = 0; i < Recently_instituteList_ids.length; i++) {
          this.getInstituteDetailsById(Recently_instituteList_ids[i], i);
        }
      }

      this.InstituteUsersService.getInstituteDetailsById(
        this.globals.institute_details.institute.id
      ).then(
        (data: any) => {
          this.selected_institute = data['data'][0];
          this.selected_institute.fein_attempt = attempt.fein_attempt;
          this.selected_institute.institute_owners_attempt =
            attempt.institute_owners_attempt;
          this.selected_institute.institute_types_attempt =
            attempt.institute_types_attempt;
          this.selected_institute.primary_contact_attempt =
            attempt.primary_contact_attempt;

          this.globals.selected_institute = this.selected_institute;
          this.CookieService.set(
            'selected_institute',
            window.btoa(JSON.stringify(this.selected_institute)),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.saveRecentlyInstitutes(this.selected_institute.id);
          this.btnDisabled = false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    }
  }

  getInstituteDetailsById(institute_id, index) {
    this.InstituteUsersService.getInstituteDetailsById(institute_id).then(
      (data: any) => {
        if (data['data'][0]) {
          this.Recently_instituteList[index] = data['data'][0];
        }
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getInstitutes() {
    //this.globals.isLoading = true;
    this.InstituteUsersService.getInstituteList().then(
      (data: any) => {
        data['data'].unshift({
          id: null,
          institute_name: 'Select an Organization',
          is_active: 1,
          provider_id: '',
        });
        this.globals.isLoading = false;
        this.instituteList = data['data'];
        this.instituteListFilterData = data['data'];
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getRoles() {
    this.InstituteUsersService.getConnectorRoles().then(
      (data) => {
        data['data'].unshift({
          id: null,
          name: 'Select Role',
        });
        this.roleList = data['data'];
        this.roleListFilterData = data['data'];
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getConfigurationData() {
    this.ConfigurationService.getAll({
      key: ['InstituteTypes', 'InstituteOwnership'],
      status: '1',
    }).then(
      (data) => {
        var instituteTypeList = data['data']['InstituteTypes'];
        var defaultItem =
          instituteTypeList.length > 0
            ? { id: '', display_text: 'Select Type' }
            : { id: '', display_text: 'No Type Found!' };
        this.instituteTypeList.push(defaultItem);
        this.instituteTypeList = [
          ...this.instituteTypeList,
          ...instituteTypeList,
        ];
        this.instituteTypeListFilterData = this.instituteTypeList;

        var instituteOwnershipList = data['data']['InstituteOwnership'];
        var defaultItem =
          instituteOwnershipList.length > 0
            ? { id: '', display_text: 'Select Ownership' }
            : { id: '', display_text: 'No Ownership Found!' };
        this.instituteOwnershipList.push(defaultItem);
        this.instituteOwnershipList = [
          ...this.instituteOwnershipList,
          ...instituteOwnershipList,
        ];
        this.instituteOwnershipListFilterData = this.instituteOwnershipList;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getSystems() {
    
    this.SystemService.getActiveSystem().then(
      (data) => {
        let systemList = data['data'];
        var defaultItem =
          systemList.length > 0
            ? { id: '', system: 'Select System' }
            : { id: '', system: 'No System Found!' };
        this.systemList.push(defaultItem);
        this.systemList = [...this.systemList, ...systemList];
        // var otherItem = { id: 'other_system', system: 'Other' };
        // this.systemList.push(otherItem);
        this.systemListFilterData = this.systemList;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  checkExistSystem() {
    var system = this.institute_other_system.toLowerCase();

    this.other_system_errors.required = false;
    this.other_system_errors.minlength = false;

    if (system.length == 0) {
      this.other_system_errors.required = true;
    } else if (system.length < 2) {
      this.other_system_errors.minlength = true;
    }
    if (
      system != 'other' &&
      this.systemListFilterData.some(
        (obj) => obj.system.toLowerCase().trim() == system.trim()
      )
    ) {
      this.system_already_in_list = true;
    } else {
      this.system_already_in_list = false;
    }
  }

  selectSystem(item) {
    this.institute_system_value = item.system;
    if (item.id != '') {
      if (this.institute_system_value == 'Other') {
        this.is_other_system = true;
      } else {
        this.is_other_system = false;
        this.system_already_in_list = false;
      }
    } else {
      this.is_other_system = false;
    }
  }

  instituteFilter(value) {
    this.instituteList = this.instituteListFilterData.filter(
      (s) => s.institute_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  roleFilter(value) {
    this.roleList = this.roleListFilterData.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  getInstituteAdmin() {
    this.InstituteUsersService.getInstituteAdminById(this.institute_id).then(
      (data: any) => {
        if (data['data'].length > 0) {
          data['data'].unshift({
            id: null,
            display_text: 'Select User',
            name: '',
            email_address: '',
            first_name: '',
          });
        }
        this.globals.isLoading = false;
        this.userList = data['data'];
        this.userListFilterData = data['data'];

        // if (this.selected_institute.primary_contact_id) {
        //   this.existingPrimaryContact = this.userList.filter(obj => obj['id'] == this.selected_institute.primary_contact_id)[0];
        // }
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  instituteUserFilter(value) {
    this.userList = this.userListFilterData.filter(
      (s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  selectPrimaryContact() {
    var error = false;

    // validate system
    // if ((this.selected_institute.institute_systems == '' && !this.globals.switchPrimaryContactModel && !this.globals.selectPrimaryContactOnlyModel) && this.institute_system_id == '') {
    //   this.primaryContactRoleError = true;
    //   error = true;
    // }

    if (
      this.is_other_system &&
      (this.institute_other_system == '' ||
        this.institute_other_system.length < 2)
    ) {
      if (this.institute_other_system == '') {
        this.other_system_errors.required = true;
      }
      error = true;
    }

    // validate existing pc role
    if (
      this.selected_institute.primary_contact_id &&
      this.existing_user_role_id == null &&
      this.globals.switchPrimaryContactModel
    ) {
      this.primaryContactRoleError = true;
      error = true;
    }

    // validate pc
    if (
      this.primary_user_id == null &&
      (this.globals.switchPrimaryContactModel ||
        !this.selected_institute.primary_contact_id)
    ) {
      this.primaryContactError = true;
      error = true;
    }

    if (this.fein_number_errors.pattern) {
      error = true;
    }

    if (!error) {
      if (this.globals.switchPrimaryContactModel) {
        this.globals.selectPrimaryContactModel = false;
        this.globals.switchPrimaryContactModel = false;
        Swal.fire({
          icon: 'warning',
          title: 'Switch Primary Contact',
          text: 'Are you sure you want to perform this irreversible action?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            this.setPrimaryContact();
          } else {
            this.globals.selectPrimaryContactModel = true;
            this.globals.switchPrimaryContactModel = true;
          }
        });
      } else {
        this.setPrimaryContact();
      }
    }
  }

  setPrimaryContact() {
    let entity = {
      institute_id: this.institute_id,
      primary_user_id: this.primary_user_id != '' ? this.primary_user_id : null,
      existing_user_role_id:
        this.existing_user_role_id != '' ? this.existing_user_role_id : null,
      fein_number: this.fein_number != '' ? this.fein_number : null,
      institute_ownership_id:
        this.institute_ownership_id != '' ? this.institute_ownership_id : null,
      institute_type_id:
        this.institute_type_id != '' ? this.institute_type_id : null,
      institute_system_id:
        this.institute_system_id != '' ? this.institute_system_id : null,
      institute_other_system:
        this.institute_other_system != '' ? this.institute_other_system : null,
    };

    if (
      !entity.primary_user_id &&
      !entity.existing_user_role_id &&
      !entity.fein_number &&
      !entity.institute_ownership_id &&
      !entity.institute_type_id &&
      !entity.institute_system_id &&
      !entity.institute_other_system
    ) {
      this.closePrimaryContact();
    }

    this.globals.isLoading = true;

    this.InstituteUsersService.setPrimaryContact(entity).then(
      (data) => {
        if (this.primary_user_id && this.primary_user_id != '') {
          this.selected_institute.primary_contact_id = this.primary_user_id;
          // this.existingPrimaryContact = this.userList.filter(obj => obj['id'] == this.primary_user_id)[0];
          this.primary_user_id = null;
          this.existing_user_role_id = null;
        }
        if (this.fein_number && this.fein_number != '') {
          this.selected_institute.fein_number = this.fein_number;
          this.fein_number = '';
        }
        if (this.institute_ownership_id && this.institute_ownership_id != '') {
          this.selected_institute.institute_owners = {
            id: this.institute_type_id,
            display_text: this.instituteOwnershipListFilterData.filter(
              (obj) => obj['id'] == this.institute_ownership_id
            )[0]['display_text'],
          };
          this.institute_ownership_id = '';
        }
        if (this.institute_type_id && this.institute_type_id != '') {
          this.selected_institute.institute_types = {
            id: this.institute_type_id,
            display_text: this.instituteTypeListFilterData.filter(
              (obj) => obj['id'] == this.institute_type_id
            )[0]['display_text'],
          };
          this.institute_type_id = '';
        }

        if (this.institute_system_id && this.institute_system_id != '') {
          this.selected_institute.institute_systems = {
            id: this.institute_system_id,
            system: this.institute_system_value,
          };
          this.institute_system_id = '';
        }

        this.CookieService.set(
          'selected_institute',
          window.btoa(JSON.stringify(this.selected_institute)),
          365,
          '/',
          this.globals.CookieDomainUrl
        );
        this.globals.saveRecentlyInstitutes(this.selected_institute.id);

        this.globals.isLoading = false;

        if (this.globals.switchPrimaryContactModel) {
          this.globals.sweetAlert(
            'success',
            'Primary Contact Updated',
            'Primary Contact has been updated successfully.'
          );
        } else {
          this.globals.sweetAlert(
            'success',
            'Information Updated',
            'Information has been updated successfully.'
          );
        }

        this.closePrimaryContact();
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  selectOrg() {
    if(!this.selected_institute.is_active) {
      this.btnDisabled = true;
       return;
       }
    if (this.institute_id != null) {
      this.orgError = false;
      this.CookieService.set(
        'selected_institute',
        window.btoa(JSON.stringify(this.selected_institute)),
        365,
        '/',
        this.globals.CookieDomainUrl
      );
      this.globals.saveRecentlyInstitutes(this.selected_institute.id);

      if (
        this.globals.institute_details.role_value != 2 &&
        this.globals.institute_details.role_value != 1
      ) {
        let institute_details = this.globals.authData.list.filter(
          (s) => s.institute && s.institute.id == this.institute_id
        )[0];
        this.CookieService.set(
          'institute_details',
          window.btoa(JSON.stringify(institute_details)),
          365,
          '/',
          this.globals.CookieDomainUrl
        );
      }
      window.location.reload();
    } else {
      this.orgError = true;
    }
  }

  clearSelectedOrganization() {
    //localStorage.removeItem('selected_institute');
    this.CookieService.delete(
      'selected_institute',
      '/',
      this.globals.CookieDomainUrl
    );
    this.globals.selected_institute = null;
    window.location.reload();
  }

  filterData(id) {
    if(id.id != null){
      this.globals.isLoading = true;

    }
    this.institute_id = id.id;
    //this.selected_institute = id;
    this.orgError = false;
    if (this.institute_id != null) {
      // this.btnDisabled = true;
      this.InstituteUsersService.getInstituteDetailsById(
        this.institute_id
      ).then(
        (data: any) => {
          this.selected_institute = data['data'][0];
          if(!this.selected_institute.is_active) {
            this.btnDisabled = true;
            
          } else {
            this.btnDisabled = false;
          }
          this.globals.isLoading = false;

        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    } else {
      this.selected_institute = null;
      this.orgError = true;
    }
  }

  close() {
    this.globals.dialogOpened = false;
    if (
      this.globals.selected_institute == null &&
      (this.router.url == '/dashboard' ||
        this.router.url == '/manage/users' ||
        this.router.url == '/manage/user/connect' ||
        this.router.url == '/manage/cohort' ||
        this.router.url == '/manage/cohort/connect' ||
        this.router.url == '/manage/user/connection-history' ||
        this.router.url == '/manage/user/upload-history' ||
        this.router.url == '/manage/division/list' ||
        this.router.url == '/manage/division/add' ||
        this.router.url == '/manage/unit/list' ||
        this.router.url == '/manage/unit/add' || this.router.url == '/manage/subscription/list' ||
       this.router.url == '/manage/subscription/add' ||
       this.router.url == '/manage/subscription-department/list' ||
       this.router.url == '/manage/assign-subscription' ||
       this.router.url == '/manage/assigned-user-subscription/list' ||
       this.router.url == '/manage/assign-user-subscription')

    ) {
      window.history.back();
      //window.location.href = this.globals.baseUrl + '/overview-dashboard';
    }
  }

  closePrimaryContact() {
    

    if (this.globals.selected_institute == null) {
      window.location.href = this.globals.baseUrl + '/overview-dashboard';
    } else {
      if (!this.globals.selectPrimaryContactOnlyModel) {
        this.selected_institute.fein_attempt = 1;
        this.selected_institute.institute_types_attempt = 1;
        this.selected_institute.institute_owners_attempt = 1;
        this.selected_institute.institute_systems_attempt = 1;
        this.CookieService.set(
          'selected_institute',
          window.btoa(JSON.stringify(this.selected_institute)),
          365,
          '/',
          this.globals.CookieDomainUrl
        );
        this.globals.saveRecentlyInstitutes(this.selected_institute.id);
        this.globals.selectPrimaryContactModel = false;
        this.globals.switchPrimaryContactModel = false;
        this.globals.selectPrimaryContactOnlyModel = false;
        window.location.reload();
      } else {
        this.globals.selectPrimaryContactModel = false;
        this.globals.switchPrimaryContactModel = false;
        this.globals.selectPrimaryContactOnlyModel = false;
        window.location.reload();
      }
    }
  }

  cancelPrimaryContact() {
    if (
      this.selected_institute.primary_contact_id ||
      this.globals.institute_details.role_value == 2 ||
      this.globals.institute_details.role_value == 1
    ) {
      this.globals.selectPrimaryContactModel = false;
      this.globals.switchPrimaryContactModel = false;
      this.globals.selectPrimaryContactOnlyModel = false;
    }
  }

  checkFeinNumber() {
    this.fein_number_errors = {
      required: false,
      pattern: false,
    };
    if (this.fein_number == null || this.fein_number == '') {
      // this.fein_number_errors.required = true;
    } else if (!new RegExp('^[0-9]{6,6}$').test(this.fein_number)) {
      this.fein_number_errors.pattern = true;
    }
  }

  instituteTypeFilter(value) {
    this.instituteTypeList = this.instituteTypeListFilterData.filter(
      (s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  instituteOwnershipFilter(value) {
    this.instituteOwnershipList = this.instituteOwnershipListFilterData.filter(
      (s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  systemFilter(value) {
    this.systemList = this.systemListFilterData.filter(
      (s) => s.system.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  select_view() {
    let OESS_Admin_present = false;
    let AMSN_Admin_present = false;
    let Individual_present = false;
    let instituteList = [];

    let instituteList_rec = [];
    this.globals.authData.list.forEach(function (s) {
      if (s.role_value == 1) {
        OESS_Admin_present = true;
      } else if (s.role_value == 2) {
        OESS_Admin_present = true;
      } else if (s.role_value == 5) {
        Individual_present = true;
      }
      if (s.institute != null && s.institute != '' && s.role_value != 4) {
        instituteList.push(s);
        instituteList_rec.push(s.institute.id);
      }
    });

    this.OESS_Admin_present = OESS_Admin_present;
    this.AMSN_Admin_present = AMSN_Admin_present;
    this.Individual_present = Individual_present;

    let instituteList1 = [];
    instituteList.forEach(function (obj) {
      let data = {
        id: obj.institute.id,
        institute_name: obj.institute.institute_name,
      };
      instituteList1.push(data);
    });
    instituteList1.unshift({
      id: null,
      institute_name: 'Select Organization',
      is_active: 1,
      provider_id: '',
    });
    this.instituteList = instituteList1;
    this.instituteListFilterData = instituteList1;

    let Recently_instituteList_ids = [];
    let Recently_institutes = this.globals.Recently_institutes;
    Recently_institutes.forEach(function (s) {
      let index = $.inArray(s, instituteList_rec);
      if (index !== -1) {
        Recently_instituteList_ids.push(s);
        instituteList_rec.splice(index, 1);
      }
    });
    let end = 3 - Recently_instituteList_ids.length;
    if (Recently_instituteList_ids.length < 3) {
      for (let i = 0; i < end; i++) {
        if (instituteList_rec.length > i) {
          Recently_instituteList_ids.push(instituteList_rec[i]);
        }
      }
    }
    if (Recently_instituteList_ids.length > 0) {
      for (let i = 0; i < Recently_instituteList_ids.length; i++) {
        this.getInstituteDetailsById(Recently_instituteList_ids[i], i);
      }
    }

    $('.switch_view_block').addClass('active_view');
  }
  select_view_close() {
    $('.switch_view_block').removeClass('active_view');
    
  }

  switchOrg(selected_institute) {
    this.CookieService.set(
      'selected_institute',
      window.btoa(JSON.stringify(selected_institute)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    this.globals.saveRecentlyInstitutes(selected_institute.id);

    if (
      this.globals.institute_details.role_value != 2 &&
      this.globals.institute_details.role_value != 1
    ) {
      let institute_details = this.globals.authData.list.filter(
        (s) => s.institute && s.institute.id == selected_institute.id
      )[0];
      this.CookieService.set(
        'institute_details',
        window.btoa(JSON.stringify(institute_details)),
        365,
        '/',
        this.globals.CookieDomainUrl
      );
    }
    window.location.reload();
  }

  switchRole(role_value) {
    let institute_details = this.globals.authData.list.filter(
      (s) => s.role_value == role_value
    )[0];
    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.reload();
  }

  SwitchOrgButtonClicked(){
    this.globals.dialogOpened = true
    const current_selected_institute =  this.CookieService.get(
      'selected_institute')
      const current_selected_institute_data = JSON.parse(window.atob(current_selected_institute));
    if(current_selected_institute_data != null){
      this.simulateSelectionChange(current_selected_institute_data);
    }

  }

  simulateSelectionChange(selectedItem: any) {
    this.institute_id = selectedItem.id; 
    this.filterData(selectedItem);
    this.cdr.detectChanges();
  }

  onInstituteChange(instituteId : any){
    console.log(instituteId)
    this.btnDisabled = false
  }

  
}
