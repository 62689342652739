<div class="inner_content_block">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-12 col-lg-12 col-md-12">
          <div class="notfound_block">
            <div class="row">
              <div class="col-12 col-xl-8 col-lg-8 col-md-12">
                <div class="not_found_title_block">
                  <img src="{{globals.cndURL}}assets/images/amsn-auth.jpg" alt="" class="img-fluid" />
                <h1 class="float-start">Authentication Failed</h1>
                <div class="clearfix"></div>
              </div>
              <div class="not_found_content">
                <p class="padding_15">We're sorry, we were unable to authenticate with your account.</p>
                <p class="padding_15">You may also return to the <a href="{{globals.amsnLogoutUrl}}">IFMA Login</a>.</p>
              </div>
              </div>
              <div class="col-12 col-xl-4 col-lg-4 col-md-12">
                <div class="contact_block">
                  <p>Please contact us with any questions you may have about IFMA.</p>
                  <a href="#" target="_blank" class="all_btn themewhite_btn">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>