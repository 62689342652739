export const environment = {
  production: true,

  baseUrl: ' https://connector.ifma-sat.uatbyopeneyes.com',
  ssoLoginUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/?redirectUrl=',
  ssoLogoutUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/Account/LogOff/?redirectUrl=',
  apiUrl: 'https://api.ifma-sat.uatbyopeneyes.com/api',
  covidUrl: 'https://covid.amsn.thisistheprogress.com',
  dcmUrl: 'https://sat.ifma-sat.uatbyopeneyes.com',
  adminUrl: 'https://admin.ifma-sat.uatbyopeneyes.com',
  CookieDomainUrl: 'ifma-sat.uatbyopeneyes.com',
  authUrl: 'https://mysat.ifma-sat.uatbyopeneyes.com',
  cndURL: 'https://assets.ifma-sat.uatbyopeneyes.com/',
  TestMode: true,
  env: 'prod'
};

// https://connector.ifma-sat.uatbyopeneyes.com/
// https://assets.ifma-sat.uatbyopeneyes.com/
