import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpClient, private globals: Globals) { }

  getAll(entity:any, type:any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + "/coupons/get-all", entity,type)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  update(entity, id) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/coupons/addUpdate/' + id, entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  add(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/coupons/addUpdate', entity)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getById(id) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/coupons/get-by-id/' + id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  delete(id) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/coupons/delete/' + id)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  

}
