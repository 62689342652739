import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
declare var $, Swal: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  constructor(private AuthService: AuthService, private router: Router, public globals: Globals) { }

  ngOnInit(): void { 
    const body = document.querySelector('body');
    body.style.setProperty('--screen-height', $(window).height() + "px");

    $(document).on("click", function(event){
        var $trigger = $(".header_dropdown");
        if($trigger !== event.target && !$trigger.has(event.target).length){
            $(".header_dropdown .dropdown-menu").removeClass("show");
            $(".header_dropdown").removeClass("show");
        }
        $(".header_dropdown .dropdown-menu a").on("click", function(event){
          $(".header_dropdown .dropdown-menu").removeClass("show");
          $(".header_dropdown").removeClass("show");
        });        
    });
  }

  logout() { 
    Swal.fire({
      icon: 'warning',
      title: 'Logout',
      text: 'Are you sure you want to logout?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.globals.isLoading = true;
        this.AuthService.logout()
        .then((data) => { 
          //this.globals.isLoading = false;  
          window.location.href = this.globals.amsnLogoutUrl;
        },
        (error) => {
          window.location.href = this.globals.amsnLogoutUrl;
          // this.globals.isLoading = false;
          // this.globals.errorSweetAlert();
        });
      }
    })
  }
  dropdown_open(){
    $(".header_dropdown").toggleClass("show");
    $(".header_dropdown .dropdown-menu").toggleClass("show");
  }
}
