import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RegistrationService } from '../services/registration.service';
declare var $, Swal: any;

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.css']
})
export class SelectOrganizationComponent implements OnInit {

  instituteList = [];

  constructor(public globals: Globals, private router: Router, private AuthService: AuthService, private RegistrationService: RegistrationService) { }

  ngOnInit(): void {
    this.RegistrationService.getAdminInstitutes({'user_id':this.globals.authData.id})
    .then((data) => {
      this.instituteList = data['data'];
    },
    (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
  }

}
