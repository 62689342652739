import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { ConfigurationService } from '../app-configuration/services/configuration.service';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  CurrentVersion = '0.1.0';

  constructor(public globals: Globals, private ConfigurationService: ConfigurationService) { }

  ngOnInit(): void {
    var currentYear = (new Date()).getFullYear();
    $(".footer_year").html(currentYear);
    this.getData();
  }

  getData() {
    this.globals.isLoading = true;
    this.ConfigurationService.getAll({ "key": ["CurrentVersion"], "status": null, "product":0 })
    .then((data) => {
      this.globals.isLoading = false;
      // this.CurrentVersion = data['data']['CurrentVersion'][0]?.display_text;
    },
    (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
  }

}
