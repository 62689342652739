import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient, private globals: Globals) { }

  registration(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/organization-signup', entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkOrganization(entity) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/organization-check', entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getActiveState(active = 1) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/state/get-state/' + active)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getCountyByState(id, is_active = 1) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/state/get-county/' + id + '/' + is_active)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAdminInstitutes(data) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/institute/admin-user', data)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

}
