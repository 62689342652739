<!-- <div class="inner_content_block register_main_block"> -->
<div class="inner_content_block register_main_block wo_org"
    [ngClass]="{'only_register':!(globals.authData.list.length>1) || globals.institute_details == null}">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start margin_title">Enroll Organization</h1>
                    <div class="clearfix"></div>
                    <button kendoButton routerLink="/manage/organization/list"
                        class="all_btn themeoption_btn float-end">List of All Organizations
                    </button>
                    <p class="padding_15">Please fill in the fields below to enroll organization. Required fields
                        are marked with an <span class="red">*</span> asterisk.</p>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <form class="k-form" [formGroup]="registerForm">
                    <div class="register_step_block">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
                                <ul class="nav nav-tabs" id="RegisterTab" role="tablist">
                                    <li class="nav-item" (click)="instituteInfoPrevious()">
                                        <a class="nav-link active" id="checkOrg-tab" role="tab" aria-controls="checkOrg"
                                            aria-selected="false"><span>1</span><i class="fa fa-check"></i>Enroll
                                            Organization</a>
                                    </li>
                                    <li class="nav-item" (click)="checkOrgSubmit()">
                                        <a class="nav-link" id="institute-tab" role="tab" aria-controls="institute"
                                            aria-selected="false"><span>2</span><i class="fa fa-check"></i>
                                            Organization Details</a>
                                    </li>
                                    <li class="nav-item" (click)="cohortInfoSubmit()">
                                        <a class="nav-link" id="cohortinfo-tab" role="tab" aria-controls="cohortinfo"
                                            aria-selected="false"><span>3</span><i class="fa fa-check"></i>Cohort
                                            Details</a>
                                    </li>
                                    <li class="nav-item" (click)="subscriptionInfoSubmit()">
                                        <a class="nav-link" id="subscriptioninfo-tab" role="tab"
                                            aria-controls="subscriptioninfo" aria-selected="false"><span>4</span><i
                                                class="fa fa-check"></i>Subscription
                                            Details</a>
                                    </li>
                                    <li class="nav-item" (click)="personalInfoSubmit()">
                                        <a class="nav-link" id="confirm-tab" role="tab" aria-controls="confirm"
                                            aria-selected="false">
                                            <span>5</span><i class="fa fa-check"></i>Confirmation</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10">
                                <div class="tab-content" id="RegisterTabContent">
                                    <div class="tab-pane fade show active" id="checkOrg" role="tabpanel"
                                        formGroupName="checkOrg">
                                        <div class="grey_form_block">
                                            <h5>Enroll Organization</h5>
                                            <div class="row">
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 pb-4">
                                                    <kendo-formfield>
                                                        <label><span>*</span> Organization Name</label>
                                                        <input formControlName="institute_name" #institute_name
                                                            maxlength="250" kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.checkOrg['controls'].institute_name.errors?.required">
                                                            Please enter organization name.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.checkOrg['controls'].institute_name.errors?.pattern">
                                                            Please enter valid organization name. Allowed special
                                                            characters are dot(.), and(&), comma(,) and single
                                                            quotation(').
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>

                                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                                    <kendo-formfield>
                                                        <label><span>*</span> Last 6 Digits of FEIN</label>
                                                        <input formControlName="fein_number" #fein_number maxlength="6"
                                                            kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.checkOrg['controls'].fein_number.errors?.required">
                                                            Please enter FEIN.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.checkOrg['controls'].fein_number.errors?.pattern">
                                                            Please enter valid FEIN.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div> -->

                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> State</label>
                                                        <kendo-dropdownlist #state_id formControlName="state_id"
                                                            [data]="stateList" [textField]="'name'" [valueField]="'id'"
                                                            [valuePrimitive]="true" [filterable]="true"
                                                            (filterChange)="stateFilter($event)"
                                                            (valueChange)="getCounties($event)"
                                                            (selectionChange)="selectState($event)">
                                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                                <h6>NO STATE FOUND</h6>
                                                            </ng-template>
                                                        </kendo-dropdownlist>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.checkOrg['controls'].state_id.errors?.required">
                                                            Please select state.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="text-center">
                                            <button type="button" (click)="checkOrgSubmit()"
                                                class="all_btn theme_btn">Next</button>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="tab-pane fade" id="institute" role="tabpanel"
                                        formGroupName="instituteInfo">
                                        <div class="grey_form_block">
                                            <h5>Add Organization Details</h5>
                                            <div class="row">

                                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                                    <kendo-formfield>
                                                        <label>Provider ID</label>
                                                        <input formControlName="provider_id" #provider_id maxlength="15"
                                                            kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].provider_id.errors?.pattern">
                                                            Please enter valid provider id. Allowed only numeric value.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div> -->
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> Phone Number</label>
                                                        <input formControlName="formated_phone_number"
                                                            #formated_phone_number maxlength="14" type="text"
                                                            kendoTextBox class="form-control" appPhoneMask />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].formated_phone_number.errors?.required">
                                                            Please enter phone number.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].formated_phone_number.errors?.pattern">
                                                            Please enter valid phone number Ex. (123) 456-7890.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>

                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> Order Id</label>
                                                        <input formControlName="order_id" maxlength="20" kendoTextBox
                                                            class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].order_id.errors?.required">
                                                            Please enter order id.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].order_id.errors?.maxlength">
                                                            Order id must be at least 20 characters long.
                                                        </kendo-formerror>

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].order_id.errors?.whitespace && !registerForm.controls.instituteInfo['controls'].order_id.errors?.maxlength && !registerForm.controls.instituteInfo['controls'].order_id.errors?.required">
                                                            Order id cannot contain only whitespace
                                                        </kendo-formerror>

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].order_id.errors?.pattern && !registerForm.controls.instituteInfo['controls'].order_id.errors?.maxlength && !registerForm.controls.instituteInfo['controls'].order_id.errors?.required && !registerForm.controls.instituteInfo['controls'].order_id.errors?.whitespace">
                                                            Invalid order id. Please use only letters, numbers,
                                                            &commat;, hyphen, and underscore.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>


                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label> Website</label>
                                                        <input formControlName="website" #website maxlength="250"
                                                            kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].website.errors?.pattern">
                                                            Please enter valid website.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>


                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label> Is Active?</label>
                                                        <kendo-switch formControlName="is_active" #is_active
                                                            [onLabel]="'Yes'" [offLabel]="'No'">
                                                        </kendo-switch>
                                                    </kendo-formfield>
                                                </div>

                                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                                    <kendo-formfield>
                                                        <label>Type</label>
                                                        <kendo-dropdownlist #institute_type_id
                                                            formControlName="institute_type_id"
                                                            [data]="instituteTypeList" [textField]="'display_text'"
                                                            [valueField]="'id'" [valuePrimitive]="true"
                                                            [filterable]="true"
                                                            (filterChange)="instituteTypeFilter($event)"
                                                            (selectionChange)="selectInstituteType($event)">
                                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                                <h6>NO TYPE FOUND</h6>
                                                            </ng-template>
                                                        </kendo-dropdownlist>
                                                    </kendo-formfield>
                                                </div> -->
                                                <!-- <kendo-formerror
                                                    *ngIf="registerForm.controls.instituteInfo['controls'].institute_type_id.errors?.required">
                                                    Please select type.
                                                </kendo-formerror> -->
                                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                                    <kendo-formfield>
                                                        <label>Ownership</label>
                                                        <kendo-dropdownlist #institute_ownership_id
                                                            formControlName="institute_ownership_id"
                                                            [data]="instituteOwnershipList" [textField]="'display_text'"
                                                            [valueField]="'id'" [valuePrimitive]="true"
                                                            [filterable]="true"
                                                            (filterChange)="instituteOwnershipFilter($event)"
                                                            (selectionChange)="selectInstituteOwnership($event)">
                                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                                <h6>NO OWNERSHIP FOUND</h6>
                                                            </ng-template>
                                                        </kendo-dropdownlist>
                                                    </kendo-formfield>
                                                </div> -->
                                                <!-- <kendo-formerror
                                                    *ngIf="registerForm.controls.instituteInfo['controls'].institute_ownership_id.errors?.required">
                                                    Please select ownership.
                                                </kendo-formerror> -->

                                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                                    <kendo-formfield>
                                                        <kendo-daterange>
                                                            <label> Registered Date</label>
                                                            <kendo-datepicker formatPlaceholder="formatPattern"
                                                                [format]="'MM/dd/yyyy'" formControlName="registered_date" #registered_date [max]="defaultCurrentDate"
                                                                class="form-control" (valueChange)="registeredDateChange($event)">
                                                            </kendo-datepicker>
                                                        </kendo-daterange>                                        
                                                    </kendo-formfield>
                                                </div> -->
                                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                                    <kendo-formfield>
                                                        <label>Are you affiliated with any system? If yes, then select
                                                            the system.</label>
                                                        <kendo-dropdownlist #system_id formControlName="system_id"
                                                            [data]="systemList" [textField]="'system'"
                                                            [valueField]="'id'" [valuePrimitive]="true"
                                                            [filterable]="true" (filterChange)="systemFilter($event)"
                                                            (selectionChange)="selectSystem($event)">
                                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                                <h6>NO SYSTEM FOUND</h6>
                                                            </ng-template>
                                                        </kendo-dropdownlist>
                                                    </kendo-formfield>
                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-12" *ngIf="is_other_system">
                                                    <kendo-formfield
                                                        [ngClass]="{'k-form-field-error': system_already_in_list}">
                                                        <label><span>*</span> Specify your System</label>
                                                        <input formControlName="other_system" #other_system
                                                            maxlength="100" kendoTextBox (keyup)="checkExistSystem()" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].other_system.errors?.required">
                                                            Please enter system.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].other_system.errors?.minlength">
                                                            System must be at least 2 characters long.
                                                        </kendo-formerror>
                                                        <div class="k-form-error" *ngIf="system_already_in_list">
                                                 F           System is already available in list.
                                                        </div>
                                                    </kendo-formfield>
                                                </div> -->

                                            </div>
                                            <h5>Address Information</h5>
                                            <div class="row">
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> Address 1</label>

                                                        <input formControlName="address1" #address1 type="text"
                                                            maxlength="100" (ngModelChange)="onAdressChange($event)"
                                                            kendoTextBox class="form-control" />
                                                        <!-- <span class="counter">{{ address1counter }}</span> -->



                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].address1.errors?.required">
                                                            Please enter address 1.
                                                        </kendo-formerror>

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].address1.errors?.minlength">
                                                            Address 1 must be at least 5 characters long.
                                                        </kendo-formerror>


                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].address1.errors?.whitespace && !registerForm.controls.instituteInfo['controls'].address1.errors?.minlength && !registerForm.controls.instituteInfo['controls'].address1.errors?.required">
                                                            Address cannot contain only whitespace
                                                        </kendo-formerror>

                                                    </kendo-formfield>

                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-textbox-container>
                                                        <label> Address 2</label>

                                                        <input formControlName="address2" maxlength="100" #address2
                                                            type="text" (ngModelChange)="onAdress2Change($event)"
                                                            kendoTextBox class="form-control" />
                                                        <!-- <span class="counter">{{ address2counter }}</span> -->


                                                    </kendo-textbox-container>
                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-textbox-container>
                                                        <label> Address 3</label>

                                                        <input formControlName="address3" maxlength="100" #address3
                                                            type="text" (ngModelChange)="onAdress3Change($event)"
                                                            kendoTextBox class="form-control" />
                                                        <!-- <span class="counter">{{ address3counter }}</span> -->

                                                    </kendo-textbox-container>
                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> City</label>
                                                        <input formControlName="city" #city maxlength="100" type="text"
                                                            kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].city.errors?.required">
                                                            Please enter city.
                                                        </kendo-formerror>


                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].city.errors?.pattern">
                                                            Please enter valid city. Allowed characters are alphabetical
                                                            letters, dash and space.
                                                        </kendo-formerror>

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].city.errors?.whitespace && !registerForm.controls.instituteInfo['controls'].city.errors?.pattern && !registerForm.controls.instituteInfo['controls'].city.errors?.required">
                                                            City cannot contain only whitespace
                                                        </kendo-formerror>

                                                    </kendo-formfield>
                                                </div>

                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> County</label>
                                                        <kendo-dropdownlist #county_id formControlName="county_id"
                                                            [data]="countyList" [textField]="'name'" [valueField]="'id'"
                                                            [valuePrimitive]="true" [filterable]="true"
                                                            (filterChange)="countyFilter($event)"
                                                            (selectionChange)="selectCounty($event)">
                                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                                <h6>NO COUNTY FOUND</h6>
                                                            </ng-template>
                                                        </kendo-dropdownlist>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].county_id.errors?.required">
                                                            Please select county.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label><span>*</span> Zip Code</label>
                                                        <input formControlName="zipcode" #zipcode maxlength="10"
                                                            type="text" kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].zipcode.errors?.required">
                                                            Please enter zip code.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.instituteInfo['controls'].zipcode.errors?.pattern">
                                                            Please enter valid zip code Ex. 12345 or 12345-6789.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                        <div class="text-center">
                                            <button type="button" (click)="instituteInfoPrevious()"
                                                class="all_btn themeoption_btn">Previous</button>
                                            <button type="button" (click)="cohortInfoSubmit()"
                                                class="all_btn theme_btn">Next</button>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="tab-pane fade" id="cohortinfo" role="tabpanel"
                                        formGroupName="personalInfo">
                                        <div class="grey_form_block">
                                            <h5>Add Cohort Details</h5>
                                            <div class="row">
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">

                                                    <kendo-formfield>
                                                        <label>First Name</label>
                                                        <input formControlName="first_name" maxlength="30" kendoTextBox
                                                            class="form-control"
                                                            (ngModelChange)="onFnamechange($event)" />
                                                        <!-- <span class="counter">{{ fnamecounter }}</span> -->


                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.personalInfo['controls'].first_name.errors?.required">
                                                            Please enter your first name.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="!registerForm.controls.personalInfo['controls'].first_name.errors?.required && registerForm.controls.personalInfo['controls'].first_name.errors?.whitespace">
                                                            First name should not be empty.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>



                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label>Last Name</label>
                                                        <input formControlName="last_name" maxlength="30" kendoTextBox
                                                            class="form-control"
                                                            (ngModelChange)="onLnamechange($event)" />
                                                        <!-- <span class="counter">{{ lnamecounter }}</span> -->

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.personalInfo['controls'].last_name.errors?.required">
                                                            Please enter your last name.
                                                        </kendo-formerror>
                                                        <kendo-formerror
                                                            *ngIf="!registerForm.controls.personalInfo['controls'].last_name.errors?.required && registerForm.controls.personalInfo['controls'].last_name.errors?.whitespace">
                                                            Last name should not be empty.
                                                        </kendo-formerror>
                                                    </kendo-formfield>
                                                </div>

                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label>Email Address</label>
                                                        <input formControlName="email_address" maxlength="250"
                                                            kendoTextBox class="form-control" />
                                                        <kendo-formerror
                                                            *ngIf="isPersonalInfoFieldInvalid('email_address')">
                                                            Please enter a valid email address.
                                                        </kendo-formerror>
                                                        <!-- <kendo-formerror
                                                      *ngIf="registerForm.controls.personalInfo['controls'].email_address.errors?.email">
                                                      Please enter a valid email address.
                                                    </kendo-formerror> -->
                                                    </kendo-formfield>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="text-center">
                                            <button type="button" (click)="cohortPrevButton()"
                                                class="all_btn themeoption_btn">Previous</button>
                                            <button type="button" (click)="subscriptionInfoSubmit()"
                                                class="all_btn theme_btn">Next</button>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                    <div class="tab-pane fade" id="subscriptioninfo" role="tabpanel"
                                        formGroupName="subscriptionInfo">
                                        <div class="grey_form_block">
                                            <h5>Add Subscription Details</h5>
                                            <div class="row">

                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label> No. of Subscriptions</label>
                                                        <!-- <input formControlName="subscription_number" kendoTextBox
                                                            class="form-control" /> -->


                                                        <kendo-numerictextbox format="##" [min]="1"
                                                            formControlName="subscription_number" #subscription
                                                            (valueChange)="noOfSubChange($event)"
                                                            [value]="registerForm.controls.subscription_number"></kendo-numerictextbox>

                                                        <kendo-formerror
                                                            *ngIf="isSubscriptionInfoFieldInvalid('subscription_number')">
                                                            Please enter no. of subscription.
                                                        </kendo-formerror>

                                                    </kendo-formfield>
                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <kendo-formfield>
                                                        <label> Order Id</label>
                                                        <input (valueChange)="orderIdChange($event)"
                                                            formControlName="subscription_order_id" kendoTextBox
                                                            class="form-control" />

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.subscriptionInfo['controls'].subscription_order_id.errors?.required">
                                                            Please enter order Id.
                                                        </kendo-formerror>

                                                        <kendo-formerror
                                                            *ngIf="registerForm.controls.subscriptionInfo['controls'].subscription_order_id.errors?.pattern">
                                                            Invalid order id. Please use only letters, numbers,
                                                            &commat;, hyphen, and underscore.

                                                        </kendo-formerror>

                                                    </kendo-formfield>
                                                </div>
                                                <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">


                                                    <kendo-formfield>
                                                        <label>Expiry Date</label>
                                                        <kendo-datepicker [min]="minDate" [max]="subscriptionExpiryDate"
                                                            (valueChange)="onDateChange($event)"
                                                            formControlName="expiry_date" placeholder="Expiry Date"
                                                            class="form-control">
                                                        </kendo-datepicker>
                                                        <kendo-formerror
                                                            *ngIf="isSubscriptionInfoFieldInvalid('expiry_date')">
                                                            Please enter Expiry Date.
                                                        </kendo-formerror>
                                                    </kendo-formfield>


                                                    <!-- <kendo-formfield>
                                                        <label>Expiry Date</label>

                                                        <kendo-datepicker [min]="getTomorrowDate()"
                                                            (valueChange)="onDateChange($event)"
                                                            [max]="subscriptionExpiryDate" formControlName="expiry_date"
                                                            placeholder="Expiry Date" class="form-control">
                                                        </kendo-datepicker>
                                                        <kendo-formerror
                                                            *ngIf="isSubscriptionInfoFieldInvalid('expiry_date')">
                                                            Please enter Expiry Date.
                                                        </kendo-formerror>
                                                    </kendo-formfield> -->

                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="text-center">
                                            <button type="button" (click)="subscriptionInfoPrevious()"
                                                class="all_btn themeoption_btn">Previous</button>
                                            <button type="button" (click)="personalInfoSubmit()"
                                                class="all_btn theme_btn">Next</button>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                    <div class="tab-pane fade" id="confirm" role="tabpanel">
                                        <div class="grey_form_block">
                                            <h5>Confirmation</h5>
                                            <p class="padding_15">To complete the enrollment, please review and verify
                                                that the information provided below is correct.</p>
                                            <div class="details_block" *ngIf="role==1">
                                                <p class="mb-3"><b>Organization Information</b></p>
                                                <div class="row">
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p class="padding_5">
                                                            <strong>Organization Name:</strong>
                                                            <span>{{instituteInfo.institute_name}}</span>
                                                        </p>
                                                    </div>
                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5" *ngIf="instituteInfo.fein_number">Last 6
                                                            Digit of FEIN: {{instituteInfo.fein_number}}</p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.fein_number">Last 6
                                                            Digit of FEIN: -</p>
                                                    </div> -->
                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5" *ngIf="instituteInfo.provider_id">Provider
                                                            ID: {{instituteInfo.provider_id}}</p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.provider_id">Provider
                                                            ID: -</p>
                                                    </div> -->
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p class="padding_5">
                                                            <strong>Phone Number:</strong>
                                                            <span>{{instituteInfo.formated_phone_number}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p class="padding_5" *ngIf="instituteInfo.website">
                                                            <strong>Website:</strong>
                                                            <span>{{instituteInfo.website}}</span>
                                                        </p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.website">
                                                            <strong>Website:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-3">
                                                        <p class="padding_5" *ngIf="instituteInfo.order_id">
                                                            <strong>Order Id:</strong>
                                                            <span>{{instituteInfo.order_id}}</span>
                                                        </p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.order_id">
                                                            <strong>Order Id:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-3">
                                                        <p class="padding_5"><strong>Is Active? :</strong>
                                                            <span>{{ instituteInfo.is_active === 'true' ||
                                                                instituteInfo.is_active == 1 ? 'Yes' : 'No' }}</span>
                                                        </p>
                                                    </div>


                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                                        <kendo-formfield>
                                                            <label><span>*</span> Is Active?</label>
                                                            <kendo-switch formControlName="is_active" #is_active [onLabel]="'Yes'"
                                                                [offLabel]="'No'">
                                                            </kendo-switch>
                                                        </kendo-formfield>
                                                    </div> -->

                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5" *ngIf="instituteInfo.institute_type">
                                                            Type:
                                                            {{instituteInfo.institute_type}}</p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.institute_type">
                                                            Type: -</p>
                                                    </div> -->
                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5" *ngIf="instituteInfo.institute_ownership">
                                                            Ownership:
                                                            {{instituteInfo.institute_ownership}}</p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.institute_ownership">
                                                            Ownership: -</p>
                                                    </div> -->

                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5" *ngIf="instituteInfo.formatted_registered_date">Registered Date: {{instituteInfo.formatted_registered_date}}</p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.formatted_registered_date">Registered Date: -</p>
                                                    </div> -->
                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5" *ngIf="instituteInfo.system">Affiliate
                                                            System: {{instituteInfo.system}}
                                                            <span
                                                                *ngIf="instituteInfo.other_system != ''">({{instituteInfo.other_system}})</span>
                                                        </p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.system">Affiliate
                                                            System: -</p>
                                                    </div> -->
                                                </div>
                                                <p class="mb-3 mt-4"><b>Address Information</b></p>
                                                <div class="row">
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p class="padding_5"><strong>Address 1:</strong>
                                                            <span>{{instituteInfo.address1}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p class="padding_5" *ngIf="instituteInfo.address2">
                                                            <strong>Address 2:</strong>
                                                            <span>{{instituteInfo.address2}}</span>
                                                        </p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.address2">
                                                            <strong>Address 2:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p class="padding_5" *ngIf="instituteInfo.address3">
                                                            <strong>Address 3:</strong>
                                                            <span>{{instituteInfo.address3}}</span>
                                                        </p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.address3">
                                                            <strong>Address 3:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-3">
                                                        <p class="padding_5" *ngIf="instituteInfo.county">
                                                            <strong>County:</strong>
                                                            <span>{{instituteInfo.county}}</span>
                                                        </p>
                                                        <p class="padding_5" *ngIf="!instituteInfo.county">
                                                            <strong>County:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-3">
                                                        <p class="padding_5"><strong>State:</strong>
                                                            <span>{{instituteInfo.state}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-3">
                                                        <p class="padding_5"><strong>City:</strong>
                                                            <span>{{instituteInfo.city}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-3">
                                                        <p class="padding_5"><strong>Zip code:</strong>
                                                            <span>{{instituteInfo.zipcode}}</span>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="details_block mt-3 pt-3 bt-dashed" *ngIf="role==0 || role==1">
                                                <p class="mb-3"><b>Cohort Information</b></p>
                                                <div class="row">
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p *ngIf="personalInfo.first_name" class="padding_5">
                                                            <strong>Full Name:</strong>
                                                            <span>{{personalInfo.first_name}}
                                                                {{personalInfo.last_name}}</span>
                                                        </p>

                                                        <p *ngIf="!personalInfo.first_name" class="padding_5">
                                                            <strong>Full Name:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                    <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <p class="padding_5">Title: {{personalInfo.title}}</p>
                                                    </div> -->
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p *ngIf="personalInfo.email_address" class="padding_5">
                                                            <strong>Email
                                                                Address:</strong>
                                                            <span>{{personalInfo.email_address}}</span>
                                                        </p>
                                                        <p *ngIf="!personalInfo.email_address" class="padding_5">
                                                            <strong>Email
                                                                Address:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details_block mt-3 pt-3 bt-dashed" *ngIf="role==0 || role==1">
                                                <p class="mb-3"><b>Subscription Information</b></p>
                                                <div class="row">
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p *ngIf="registerForm.controls.subscriptionInfo.value.subscription_number"
                                                            class="padding_5"><strong>Subscription Number:</strong>
                                                            <span>{{registerForm.controls.subscriptionInfo.value.subscription_number}}</span>
                                                        </p>

                                                        <p *ngIf="!registerForm.controls.subscriptionInfo.value.subscription_number"
                                                            class="padding_5"><strong>Subscription Number:</strong>
                                                            <span> - </span>

                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p *ngIf="registerForm.controls.subscriptionInfo.value.subscription_order_id"
                                                            class="padding_5"><strong>Subscription Order Id:</strong>
                                                            <span>{{registerForm.controls.subscriptionInfo.value.subscription_order_id}}</span>
                                                        </p>

                                                        <p *ngIf="!registerForm.controls.subscriptionInfo.value.subscription_order_id"
                                                            class="padding_5"><strong>Subscription Order Id:</strong>
                                                            <span> - </span>

                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <p *ngIf="registerForm.controls.subscriptionInfo.value.expiry_date"
                                                            class="padding_5"><strong>Expiry Date:</strong>
                                                            <span>{{registerForm.controls.subscriptionInfo.value.expiry_date
                                                                |
                                                                date: 'MMM d,y'}}</span>
                                                        </p>

                                                        <p *ngIf="!registerForm.controls.subscriptionInfo.value.expiry_date"
                                                            class="padding_5"><strong>Expiry Date:</strong>
                                                            <span> - </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="text-center">
                                            <button type="button" (click)="subscriptionInfoSubmit()"
                                                class="all_btn themeoption_btn">Previous</button>
                                            <button type="button" (click)="registration()"
                                                class="all_btn theme_btn">Enroll</button>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <p class="float-end account_margin">Already have an account? <a routerLink="/">Login</a></p> -->
                </form>
            </div>
        </div>
    </div>
</div>