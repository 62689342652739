import { Component, NgZone, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { DashboardService } from './../services/dashboard.service';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CookieService } from 'ngx-cookie-service';
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import { load } from '@amcharts/amcharts4/.internal/core/utils/Net';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
})
export class AdminDashboardComponent implements OnInit {

  statusList: any = ['All', 'Organizations'];
  institute_id = this.globals.institute_details?.institute.id;
  systemCount = 0;
  organizationCount = 0;
  divisionCount = 0;
  unitCount = 0;
  userCount = 0;
  loader = {
    organizationsBySystems: false,
    organizationsByOwnership: false,
    organizationsByType: false,
    recentlyConnectedOrganizations: false,
    topOrganizations: false,
    stateWiseOrganizationsAndUsers: false
  };
  dataNotAvailable = {
    organizationsBySystems: false,
    organizationsByOwnership: false,
    organizationsByType: false,
    topOrganizations: false,
    stateWiseOrganizationsAndUsers: false
  };
  recentlyConnectedOrganizations: any;
  stateForm: UntypedFormGroup;

  system_sort = 'system_name';
  paginationEntity = {
    limit: 10,
    offset: 0,
    searchData: {
      status: '',
      searchQuery: ''
    },
    sortOrder: [{
      field: this.system_sort,
      dir: 'asc'
    }]
  };  
  listItems = [
    { 'text': 'System', value: 'system_name' },
    { 'text': 'Organization', value: 'institute_count' },    
    { 'text': 'Division', value: 'department_count' },
    { 'text': 'Unit', value: 'unit_count' },
    { 'text': 'User', value: 'user_count' }
  ];

  constructor(public globals: Globals, private formBuilder: UntypedFormBuilder, private cookieService: CookieService, private DashboardService: DashboardService) { }

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Overview', '', ''];
    this.globals.isLoading = false;
    this.stateForm = this.formBuilder.group({
      switch_state: [false]
    });
    this.getData();

    // am4core.ready(function() {

    //   am4core.addLicense("CH236795548");
    //   am4core.useTheme(am4themes_frozen);

    //   var chart = am4core.create('org_user_system_bar_chart', am4charts.XYChart)
      
    //   chart.legend = new am4charts.Legend()
    //   chart.legend.position = 'bottom'
    //   chart.legend.paddingBottom = 20
    //   //chart.legend.labels.getIndex(2).disabled = true;
    //   chart.maskBullets = false;
      
    //   var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    //   xAxis.dataFields.category = "system";
    //   xAxis.renderer.grid.template.disabled = true;
    //   xAxis.renderer.cellStartLocation = 0.1
    //   xAxis.renderer.cellEndLocation = 0.9
    //   xAxis.renderer.grid.template.location = 0;
      
    //   var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //   yAxis.min = 0;
    //   yAxis.renderer.grid.template.disabled = true;
    //   yAxis.renderer.axis.opacity = 0;
      
    //   let label = xAxis.renderer.labels.template;
    //   label.wrap = true;
    //   label.textAlign = "middle";
    //   label.maxWidth = 90;
    //   label.fontSize = 10;

    //   chart.scrollbarX = new am4core.Scrollbar();
    //   chart.scrollbarX.parent = chart.bottomAxesContainer;
    //   chart.zoomOutButton.visible = false;
    //   chart.seriesContainer.resizable = false;
    
    //   xAxis.start = 0;
    //   xAxis.end = 0.6050;
      
    //   function createSeries(value, name, number, color, hide) {
    //       var series = chart.series.push(new am4charts.ColumnSeries())
    //       series.dataFields.valueY = value
    //       series.dataFields.categoryX = 'system'
    //       series.name = name
    //       series.events.on("ready", arrangeColumns);
    //       series.events.on("hidden", arrangeColumns);
    //       series.events.on("shown", arrangeColumns);

    //       series.columns.template.column.cornerRadiusTopRight = 5;
    //       series.columns.template.column.cornerRadiusTopLeft = 5;
    //       series.columns.template.column.maxWidth = 10;
    //       series.columns.template.strokeOpacity = 0;
    //       series.columns.template.fill = am4core.color(color);
    //       series.hidden = hide;
      
    //       var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    //       labelBullet.label.verticalCenter = "bottom";
    //       labelBullet.label.dy = 2;
    //       labelBullet.label.text = number;
    //       labelBullet.label.fontSize = 10;

    //       return series;
    //   }
      
    //   chart.data = [
    //       {
    //           system: 'Advocate Health Care \n [bold] 1,340 \nUsers[/]',
    //           organization: 34,
    //           division: 22,
    //           unit: 36
    //       },
    //       {
    //         system: 'Ascension Health \n [bold] 4,932 \nUsers[/]',
    //           organization: 23,
    //           division: 9,
    //           unit: 21
    //       },
    //       {
    //         system: 'Catholic Healthcare West \n [bold] 5,252 \nUsers[/]',
    //           organization: 46,
    //           division: 66,
    //           unit: 53
    //       },
    //       {
    //         system: 'Cleveland Clinic \n [bold] 1,662 \nUsers[/]',
    //           organization: 13,
    //           division: 24,
    //           unit: 26
    //       },
    //       {
    //         system: 'CommonSpirit Health \n [bold] 2,099 \nUsers[/]',
    //           organization: 9,
    //           division: 13,
    //           unit: 89
    //       },
    //       {
    //         system: 'Community Health Systems \n [bold] 8,322 \nUsers[/]',
    //           organization: 2,
    //           division: 52,
    //           unit: 78
    //       },
    //       {
    //         system: 'Department of Veterans Affairs \n [bold] 6,924 \nUsers[/]',
    //           organization: 4,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Encompass Health Corporation \n [bold] 1,333 \nUsers[/]',
    //           organization: 40,
    //           division: 19,
    //           unit: 52
    //       },
    //       {
    //         system: 'HCA Healthcare \n [bold] 402 \nUsers[/]',
    //           organization: 2,
    //           division: 5,
    //           unit: 19
    //       },
    //       {
    //         system: 'Inspira Health Network \n [bold] 2,755 \nUsers[/]',
    //           organization: 9,
    //           division: 27,
    //           unit: 59
    //       },
    //       {
    //         system: 'Kaiser Permanente Northern California \n [bold] 5,500 \nUsers[/]',
    //           organization: 4,
    //           division: 33,
    //           unit: 73
    //       },
    //       {
    //         system: 'Kaiser Permanente Southern California \n [bold] 2,110 \nUsers[/]',
    //           organization: 15,
    //           division: 60,
    //           unit: 80
    //       },
    //       {
    //         system: 'Mercy Health System \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Northwestern Medicine Health System \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'NY Presbyterian Healthcare System \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Other \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Providence St. Joseph Health \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Select Medical Corporation \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Tenet Healthcare \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Trinity Health \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Universal Health Services \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'University of California Health \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       },
    //       {
    //         system: 'Virtua Health System \n [bold] 40 \nUsers[/]',
    //           organization: 40,
    //           user: 55,
    //           division: 60,
    //           unit: 50
    //       }
    //   ]
      
    //   createSeries('organization', 'Organizations', '{organization}', '#209cb7', false);
    //  // createSeries('user', 'Users', '{user}', '#209cb7');
    //   createSeries('division', 'Divisions', '{division}', '#f5cd60', false);
    //   createSeries('unit', 'Units', '{unit}', '#0c4aa3', true);

    //   function arrangeColumns() {

    //     var series = chart.series.getIndex(0);
    
    //     var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    //     if (series.dataItems.length > 1) {
    //         var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
    //         var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
    //         var delta = ((x1 - x0) / chart.series.length) * w;
    //         if (am4core.isNumber(delta)) {
    //             var middle = chart.series.length / 2;
    
    //             var newIndex = 0;
    //             chart.series.each(function(series) {
    //                 if (!series.isHidden && !series.isHiding) {
    //                     series.dummyData = newIndex;
    //                     newIndex++;
    //                 }
    //                 else {
    //                     series.dummyData = chart.series.indexOf(series);
    //                 }
    //             })
    //             var visibleCount = newIndex;
    //             var newMiddle = visibleCount / 2;
    
    //             chart.series.each(function(series) {
    //                 var trueIndex = chart.series.indexOf(series);
    //                 var newIndex = series.dummyData;
    
    //                 var dx = (newIndex - trueIndex + middle - newMiddle) * delta
    
    //                 series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
    //                 series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
    //             })
    //         }
    //     }
    // }
      
    //   }); // end am4core.ready()

  }

  getData() {
    this.loader = {
      organizationsBySystems: true,
      organizationsByOwnership: true,
      organizationsByType: true,
      recentlyConnectedOrganizations: true,
      topOrganizations: true,
      stateWiseOrganizationsAndUsers: true
    };
    this.getCounts();
    // this.getOrganizationBySystem();
    // this.getOrganizationByOwnership();
    // this.getOrganizationByType();
    this.getRecentlyConnectedOrganizations();
    // this.getTopOrganization();
    this.getStateWiseOrganization();
  }

  getCounts() {
    this.DashboardService.getCountForAdmin({ 'type': 'CountData' })
      .then((data) => {
        this.systemCount = data['data'].system_counts;
        this.organizationCount = data['data'].orginazation_counts;
        this.divisionCount = data['data'].division_count;
        this.unitCount = data['data'].unit_count;
        this.userCount = data['data'].user_count;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  changeSortBySystem(){
    this.loader.organizationsBySystems = true;
    // this.getOrganizationBySystem();
  }

  // getOrganizationBySystem() {
  //   this.paginationEntity.sortOrder[0].field = this.system_sort;
  //   this.paginationEntity.sortOrder[0].dir = this.system_sort == 'system_name' ? 'asc' : 'desc';
  //   this.DashboardService.getOrganizationsBySystemForAdmin(this.paginationEntity)
  //     .then((data) => {

  //       var chartData = [];

  //       for (const [key, value] of Object.entries(data['data'])) {
  //         chartData.push({
  //           'system': value['system_name'] + ' \n [bold]' + value['user_count'] + '\nUsers[/]',
  //           'organization': value['institute_count'],
  //           'division': value['department_count'],
  //           'unit': value['unit_count']
  //         });                 
  //       }
  //       if (chartData.length == 0) {
  //         this.dataNotAvailable.organizationsBySystems = true;
  //       } else {
  //         this.dataNotAvailable.organizationsBySystems = false;
  //         setTimeout(function () {

  //           am4core.ready(function() {

  //           am4core.addLicense("CH236795548");
  //           am4core.useTheme(am4themes_frozen);

  //           var chart = am4core.create('org_user_system_bar_chart', am4charts.XYChart)
            
  //           chart.legend = new am4charts.Legend()
  //           chart.legend.position = 'bottom'
  //           chart.legend.paddingBottom = 20
  //           //chart.legend.labels.getIndex(2).disabled = true;
  //           chart.maskBullets = false;
            
  //           var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //           xAxis.dataFields.category = "system";
  //           xAxis.renderer.grid.template.disabled = true;
  //           xAxis.renderer.cellStartLocation = 0.1
  //           xAxis.renderer.cellEndLocation = 0.9
  //           xAxis.renderer.grid.template.location = 0;
            
  //           var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //           yAxis.min = 0;
  //           yAxis.renderer.grid.template.disabled = true;
  //           yAxis.renderer.axis.opacity = 0;
            
  //           let label = xAxis.renderer.labels.template;
  //           label.wrap = true;
  //           label.textAlign = "middle";
  //           label.maxWidth = 150;
  //           label.fontSize = 10;

  //           // chart.scrollbarX = new am4core.Scrollbar();
  //           // chart.scrollbarX.parent = chart.bottomAxesContainer;
  //           // chart.zoomOutButton.visible = false;
  //           // chart.seriesContainer.resizable = false;
          
  //           // xAxis.start = 0;
  //           // xAxis.end = 0.6050;
            
  //           function createSeries(value, name, number, color, hide) {
  //               var series = chart.series.push(new am4charts.ColumnSeries())
  //               series.dataFields.valueY = value
  //               series.dataFields.categoryX = 'system'
  //               series.name = name
  //               series.events.on("ready", arrangeColumns);
  //               series.events.on("hidden", arrangeColumns);
  //               series.events.on("shown", arrangeColumns);

  //               series.columns.template.column.cornerRadiusTopRight = 5;
  //               series.columns.template.column.cornerRadiusTopLeft = 5;
  //               series.columns.template.column.maxWidth = 10;
  //               series.columns.template.strokeOpacity = 0;
  //               series.columns.template.fill = am4core.color(color);
  //             //  series.hidden = hide;
            
  //               var labelBullet = series.bullets.push(new am4charts.LabelBullet());
  //               labelBullet.label.verticalCenter = "bottom";
  //               labelBullet.label.dy = 2;
  //               labelBullet.label.text = number;
  //               labelBullet.label.fontSize = 10;

  //               return series;
  //           }
            
  //           chart.data = chartData;
            
  //           createSeries('organization', 'Organizations', '{organization}', '#209cb7', false);
  //         // createSeries('user', 'Users', '{user}', '#209cb7');
  //           createSeries('division', 'Divisions', '{division}', '#f5cd60', false);
  //           createSeries('unit', 'Units', '{unit}', '#0c4aa3', true);

  //           function arrangeColumns() {

  //             var series = chart.series.getIndex(0);
          
  //             var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
  //             if (series.dataItems.length > 1) {
  //                 var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
  //                 var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
  //                 var delta = ((x1 - x0) / chart.series.length) * w;
  //                 if (am4core.isNumber(delta)) {
  //                     var middle = chart.series.length / 2;
          
  //                     var newIndex = 0;
  //                     chart.series.each(function(series) {
  //                         if (!series.isHidden && !series.isHiding) {
  //                             series.dummyData = newIndex;
  //                             newIndex++;
  //                         }
  //                         else {
  //                             series.dummyData = chart.series.indexOf(series);
  //                         }
  //                     })
  //                     var visibleCount = newIndex;
  //                     var newMiddle = visibleCount / 2;
          
  //                     chart.series.each(function(series) {
  //                         var trueIndex = chart.series.indexOf(series);
  //                         var newIndex = series.dummyData;
          
  //                         var dx = (newIndex - trueIndex + middle - newMiddle) * delta
          
  //                         series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
  //                         series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
  //                     })
  //                 }
  //             }
  //         }
            
  //           }); // end am4core.ready()

  //           // am4core.ready(function () {

  //           //   am4core.addLicense("CH236795548");
  //           //   am4core.useTheme(am4themes_frozen);

  //           //   var chart = am4core.create("org_user_system_chart", am4charts.XYChart);

              
  //           //   chart.data = chartData;

  //           //   var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //           //   categoryAxis.renderer.grid.template.location = 0;
  //           //   categoryAxis.dataFields.category = "system";
  //           //   categoryAxis.renderer.minGridDistance = 60;
  //           //   categoryAxis.renderer.grid.template.disabled = true;

  //           //   var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //           //   valueAxis.min = 0;
  //           //   valueAxis.renderer.grid.template.disabled = true;
  //           //   valueAxis.renderer.axis.opacity = 0;

  //           //   var series = chart.series.push(new am4charts.ColumnSeries());
  //           //   series.dataFields.categoryX = "system";
  //           //   series.dataFields.valueY = "organization_count";
  //           //   series.columns.template.strokeOpacity = 0;
  //           //   series.columns.template.column.cornerRadiusTopRight = 5;
  //           //   series.columns.template.column.cornerRadiusTopLeft = 5;
  //           //   series.columns.template.fill = am4core.color("#0c4aa3");
  //           //   series.columns.template.fillOpacity = 0.5;
  //           //   var labelBullet = series.bullets.push(new am4charts.LabelBullet());
  //           //   labelBullet.label.verticalCenter = "bottom";
  //           //   labelBullet.label.dy = -2;
  //           //   labelBullet.label.text = "{organization_count}";
  //           //   //chart.colors.step = 4;

  //           //   let label = categoryAxis.renderer.labels.template;
  //           //   label.wrap = true;
  //           //   label.textAlign = "middle";
  //           //   label.maxWidth = 95;
  //           //   label.fontSize = 11;

  //           //   // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
  //           //   // series.columns.template.adapter.add("fill", function (fill, target) {
  //           //   //  return chart.colors.getIndex(target.dataItem.index);
  //           //   // });

  //           // }); // end am4core.ready()

  //         }, 200);
  //       }

  //       this.loader.organizationsBySystems = false;

  //     },
  //       (error) => {
  //         this.dataNotAvailable.organizationsBySystems = true;
  //         this.loader.organizationsBySystems = false;
  //         this.globals.isLoading = false;
  //         this.globals.errorSweetAlert();
  //       });

  // }

  // getOrganizationByOwnership() {
  //   this.DashboardService.getOrganizationByOwnershipTypeForAdmin({ 'type': 'ownership' })
  //     .then((data) => {

  //       var organizationsByOwnershipData = data['data'];

  //       if (organizationsByOwnershipData.length == 0) {
  //         this.dataNotAvailable.organizationsByOwnership = true;
  //       } else {
  //         this.dataNotAvailable.organizationsByOwnership = false;
  //         setTimeout(function () {
  //           am4core.ready(function () {
  //             am4core.addLicense("CH236795548");
  //             am4core.useTheme(am4themes_frozen);

  //             var chart = am4core.create("org_ownership_chart", am4charts.XYChart);
  //             chart.responsive.enabled = true;

  //             var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  //             categoryAxis.renderer.grid.template.location = 0;
  //             categoryAxis.dataFields.category = "ownership";
  //             categoryAxis.renderer.minGridDistance = 5;
  //             categoryAxis.renderer.grid.template.disabled = true;

  //             var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
  //             valueAxis.min = 0;
  //             valueAxis.renderer.grid.template.disabled = true;
  //             valueAxis.renderer.axis.opacity = 0;

  //             var series = chart.series.push(new am4charts.ColumnSeries());
  //             series.dataFields.categoryY = "ownership";
  //             series.dataFields.valueX = "org_count";
  //             series.columns.template.strokeOpacity = 0;
  //             series.columns.template.column.cornerRadiusBottomRight = 5;
  //             series.columns.template.column.cornerRadiusTopRight = 5;

  //             var labelBullet = series.bullets.push(new am4charts.LabelBullet());
  //             labelBullet.horizontalCenter="right";
  //             labelBullet.label.dx = 25;
  //             labelBullet.label.text = "{org_count}";
  //             labelBullet.label.truncate = false;

  //             // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
  //             series.columns.template.adapter.add("fill", function (fill, target) {
  //               return chart.colors.getIndex(target.dataItem.index);
  //             });

  //             //  categoryAxis.sortBySeries = series;
  //            chart.data = organizationsByOwnershipData;
          
  //           }); // end am4core.ready()

  //         }, 500);
  //       }

  //       this.loader.organizationsByOwnership = false;

  //     },
  //       (error) => {
  //         this.dataNotAvailable.organizationsByOwnership = true;
  //         this.loader.organizationsByOwnership = false;
  //         this.globals.isLoading = false;
  //         this.globals.errorSweetAlert();
  //       });
  // }

  // getOrganizationByType() {
  //   this.DashboardService.getOrganizationByOwnershipTypeForAdmin({ 'type': 'Type' })
  //     .then((data) => {
  //       var organizationsByTypeData = data['data'].data;
  //       if (organizationsByTypeData.length == 0) {
  //         this.dataNotAvailable.organizationsByType = true;
  //       } else {
  //         this.dataNotAvailable.organizationsByType = false;
  //         setTimeout(function () {
  //           am4core.ready(function () {
  //             var chart = am4core.create("org_type_chart", am4charts.PieChart);
  //             am4core.addLicense("CH236795548");
  //             am4core.useTheme(am4themes_material);
  //             chart.responsive.enabled = true;

  //             var pieSeries = chart.series.push(new am4charts.PieSeries());
  //             pieSeries.dataFields.value = "org_count";
  //             pieSeries.dataFields.category = "type";

  //             chart.innerRadius = am4core.percent(50);
  //             var label = chart.seriesContainer.createChild(am4core.Label);
  //             label.html = '<span style="text-align:center; display:block;">Total<br>Organizations<br><span style="text-align:center; display:block; font-weight:bold; font-size:25px; margin-top:10px">' + data['data'].count + '</span></span>';
  //             label.horizontalCenter = "middle";
  //             label.verticalCenter = "middle";
  //             label.fontSize = 13;

  //             pieSeries.slices.template.stroke = am4core.color("#fff");
  //             pieSeries.slices.template.strokeWidth = 2;
  //             pieSeries.slices.template.strokeOpacity = 1;
  //             //pieSeries.slices.template.propertyFields.fill = "color";

  //             pieSeries.ticks.template.disabled = false;
  //             pieSeries.labels.template.text = "{org_count} Organizations";
  //             pieSeries.labels.template.fontSize = 12;
  //             pieSeries.labels.template.fill = am4core.color("black");
  //             pieSeries.labels.template.maxWidth = 100;
  //             pieSeries.labels.template.wrap = true;

  //             let slice = pieSeries.slices.template;
  //             slice.states.getKey("hover").properties.scale = 1;
  //             slice.states.getKey("active").properties.shiftRadius = 0;

  //             chart.legend = new am4charts.Legend()
  //             chart.legend.position = 'bottom'
  //             chart.legend.paddingTop = 15
  //             chart.legend.paddingBottom = 0
  //             chart.legend.fontSize = 12
  //             chart.legend.labels.template.width = 300
  //             let markerTemplate = chart.legend.markers.template;
  //             markerTemplate.width = 12;
  //             markerTemplate.height = 12;
  //             chart.legend.valueLabels.template.disabled = true;
  //             chart.data = organizationsByTypeData;

  //           }); // end am4core.ready()

  //         }, 500);
  //       }
  //       this.loader.organizationsByType = false;
  //     },
  //       (error) => {
  //         this.dataNotAvailable.organizationsByType = true;
  //         this.loader.organizationsByType = false;
  //         this.globals.isLoading = false;
  //         this.globals.errorSweetAlert();
  //       });
  // }

  getRecentlyConnectedOrganizations() {
    this.DashboardService.getCountForAdmin({ 'type': 'RecentlyConnectedOrganizations' })
      .then((data) => {
        this.recentlyConnectedOrganizations = data['data'];
        this.loader.recentlyConnectedOrganizations = false;
      },
        (error) => {
          this.loader.recentlyConnectedOrganizations = false;
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  // getTopOrganization() {
  //   this.DashboardService.getTopOrganizationForAdmin({})
  //     .then((data) => {

  //       var topOrgWithSystem = data['data'].withSystem;
  //       var topOrgWithoutSystem = data['data'].withOutSystem;

  //       var topOrgChartData = [];
  //       if (topOrgWithSystem.length > topOrgWithoutSystem.length) {
  //         for (var i = 0; i < topOrgWithSystem.length; i++) {
  //           var row = {};
  //           row['org_system'] = topOrgWithSystem[i].institute_name;
  //           row['user_count_org_system'] = topOrgWithSystem[i].users;
  //           if (topOrgWithoutSystem[i]) {
  //             row['org_without_system'] = topOrgWithoutSystem[i].institute_name;
  //             row['user_count_without_system'] = topOrgWithoutSystem[i].users;
  //           }
  //           topOrgChartData.push(row);
  //         }
  //       } else {
  //         for (var i = 0; i < topOrgWithoutSystem.length; i++) {
  //           var row = {};
  //           row['org_without_system'] = topOrgWithoutSystem[i].institute_name;
  //           row['user_count_without_system'] = topOrgWithoutSystem[i].users;
  //           if (topOrgWithSystem[i]) {
  //             row['org_system'] = topOrgWithSystem[i].institute_name;
  //             row['user_count_org_system'] = topOrgWithSystem[i].users;
  //           }
  //           topOrgChartData.push(row);
  //         }
  //       }

  //       if (topOrgChartData.length == 0) {
  //         this.dataNotAvailable.topOrganizations = true;
  //       } else {
  //         this.dataNotAvailable.topOrganizations = false;
  //         setTimeout(function () {

  //           am4core.ready(function () {

  //             am4core.addLicense("CH236795548");

  //             var mainContainer = am4core.create("with_without_org_chart", am4core.Container);
  //             mainContainer.width = am4core.percent(100);
  //             mainContainer.height = am4core.percent(100);
  //             mainContainer.layout = "horizontal";

  //             var usData = topOrgChartData;

  //             var maleChart = mainContainer.createChild(am4charts.XYChart);
  //             maleChart.paddingRight = 0;
  //             maleChart.data = JSON.parse(JSON.stringify(usData));

  //             // Create axes
  //             var maleCategoryAxis = maleChart.yAxes.push(new am4charts.CategoryAxis());
  //             maleCategoryAxis.dataFields.category = "org_system";
  //             maleCategoryAxis.renderer.grid.template.location = 0;
  //             maleCategoryAxis.renderer.inversed = true;
  //             maleCategoryAxis.renderer.minGridDistance = 15;
  //             maleCategoryAxis.renderer.grid.template.disabled = true;
  //             maleCategoryAxis.renderer.labels.template.fontSize = 13;
  //             maleCategoryAxis.renderer.labels.template.wrap = true;
  //             maleCategoryAxis.renderer.labels.template.width = 200;
  //             maleCategoryAxis.renderer.labels.template.textAlign = "end";

  //             var maleValueAxis = maleChart.xAxes.push(new am4charts.ValueAxis());
  //             maleValueAxis.renderer.inversed = true;
  //             maleValueAxis.min = 0;
  //             maleValueAxis.renderer.axis.opacity = 0;

  //             // Create series
  //             var maleSeries = maleChart.series.push(new am4charts.ColumnSeries());
  //             maleSeries.dataFields.valueX = "user_count_org_system";
  //             //maleSeries.dataFields.valueXShow = "percent";
  //             //maleSeries.calculatePercent = true;
  //             maleSeries.dataFields.categoryY = "org_system";
  //             maleSeries.interpolationDuration = 1000;
  //             maleSeries.columns.template.fill = am4core.color("#E4AD22");
  //             maleSeries.columns.template.stroke = am4core.color("#E4AD22");
  //             maleSeries.sequencedInterpolation = true;

  //             maleSeries.columns.template.column.cornerRadiusTopLeft = 5;
  //             maleSeries.columns.template.column.cornerRadiusBottomLeft = 5;

  //             let valueLabel1 = maleSeries.bullets.push(new am4charts.LabelBullet());
  //             valueLabel1.label.text = "{user_count_org_system} Users";
  //             valueLabel1.label.fontSize = 13;
  //             valueLabel1.label.dx = 40;
  //             valueLabel1.horizontalCenter = "left";
  //             valueLabel1.label.fill = am4core.color("#fff");

  //             var femaleChart = mainContainer.createChild(am4charts.XYChart);
  //             femaleChart.paddingLeft = 0;
  //             femaleChart.data = JSON.parse(JSON.stringify(usData));

  //             // Create axes
  //             var femaleCategoryAxis = femaleChart.yAxes.push(new am4charts.CategoryAxis());
  //             femaleCategoryAxis.renderer.opposite = true;
  //             femaleCategoryAxis.dataFields.category = "org_without_system";
  //             femaleCategoryAxis.renderer.grid.template.location = 0;
  //             femaleCategoryAxis.renderer.minGridDistance = 15;
  //             femaleCategoryAxis.renderer.inversed = true;
  //             femaleCategoryAxis.renderer.grid.template.disabled = true;
  //             femaleCategoryAxis.renderer.labels.template.fontSize = 13;
  //             femaleCategoryAxis.renderer.labels.template.wrap = true;
  //             femaleCategoryAxis.renderer.labels.template.width = 200;

  //             var femaleValueAxis = femaleChart.xAxes.push(new am4charts.ValueAxis());
  //             femaleValueAxis.min = 0;
  //             femaleValueAxis.renderer.axis.opacity = 0;

  //             // Create series
  //             var femaleSeries = femaleChart.series.push(new am4charts.ColumnSeries());
  //             femaleSeries.dataFields.valueX = "user_count_without_system";
  //             //femaleSeries.dataFields.valueXShow = "percent";
  //             //femaleSeries.calculatePercent = true;
  //             femaleSeries.fill = femaleChart.colors.getIndex(4);
  //             femaleSeries.stroke = femaleSeries.fill;
  //             femaleSeries.sequencedInterpolation = true;
  //             femaleSeries.columns.template.fill = am4core.color("#0061AF");
  //             femaleSeries.columns.template.stroke = am4core.color("#0061AF");
  //             femaleSeries.dataFields.categoryY = "org_without_system";
  //             femaleSeries.columns.template.column.cornerRadiusTopRight = 5;
  //             femaleSeries.columns.template.column.cornerRadiusBottomRight = 5;

  //             let valueLabel = femaleSeries.bullets.push(new am4charts.LabelBullet());
  //             valueLabel.label.text = "{user_count_without_system} Users";
  //             valueLabel.label.fontSize = 13;
  //             valueLabel.label.dx = -40;
  //             valueLabel.horizontalCenter = "right";
  //             valueLabel.label.fill = am4core.color("#fff");

  //           }); // end am4core.ready()

  //         }, 500);
  //       }

  //       this.loader.topOrganizations = false;



  //     },
  //       (error) => {
  //         this.dataNotAvailable.topOrganizations = true;
  //         this.loader.topOrganizations = false;
  //         this.globals.isLoading = false;
  //         this.globals.errorSweetAlert();
  //       });
  // }

  getStateWiseOrganization() {
    this.DashboardService.getStatewiseCountForAdmin({ 'type': 'byOrganizations' })
    .then((data) => {
      let org_data = data['data'];
      this.loader.stateWiseOrganizationsAndUsers = false;
      setTimeout(function () {

        am4core.ready(function () {
          // Create map instance
          var chart = am4core.create("map_chart_org", am4maps.MapChart);
          am4core.addLicense("CH236795548");
          chart.logo.hidden = true;
          chart.maxZoomLevel = 64;
          chart.responsive.enabled = true;
  
          // Set map definition
          chart.geodata = am4geodata_usaLow;
  
          // Set projection
          chart.projection = new am4maps.projections.AlbersUsa();
  
          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
          polygonSeries.useGeodata = true;
          polygonSeries.calculateVisualCenter = true;
  
          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.tooltipText = "{name}";
          polygonTemplate.fill = am4core.color("#209cb7");
  
          // Load data when map polygons are ready
          chart.events.on("ready", loadStores);
  
          // Loads store data
          function loadStores() {
            var loader = new am4core.DataSource();
            loader.data = org_data;
            setupStores(loader.data);  
            loader.load();
          }
  
          // Creates a series
          function createSeries(heatfield) {
            var series = chart.series.push(new am4maps.MapImageSeries());
            series.dataFields.value = heatfield;
  
            var template = series.mapImages.template;
            template.verticalCenter = "middle";
            template.horizontalCenter = "middle";
            template.propertyFields.latitude = "lat";
            template.propertyFields.longitude = "long";
            template.tooltipText = "{name}:\n[bold]{stores} Organizations[/]";
  
            var circle = template.createChild(am4core.Circle);
            circle.radius = 10;
            circle.fillOpacity = 0.7;
            circle.fill = am4core.color("#0c4aa3");
            circle.verticalCenter = "middle";
            circle.horizontalCenter = "middle";
            circle.nonScaling = true;
  
            var label = template.createChild(am4core.Label);
            label.text = "{stores}";
            label.fill = am4core.color("#fff");
            label.verticalCenter = "middle";
            label.horizontalCenter = "middle";
            label.nonScaling = true;
  
            var heat = series.heatRules.push({
              target: circle,
              property: "radius",
              min: 10,
              max: 30
            });
  
            // Set up drill-down
            series.mapImages.template.events.on("hit", function (ev) {
  
              // Determine what we've clicked on
              var data = ev.target.dataItem.dataContext;
  
              // No id? Individual store - nothing to drill down to further
              if (!data['target']) {
                return;
              }
  
              // Create actual series if it hasn't been yet created
              if (!regionalSeries[data['target']].series) {
                regionalSeries[data['target']].series = createSeries("count");
                regionalSeries[data['target']].series.data = data['markerData'];
              }  
  
              // Show new targert series
              currentSeries = regionalSeries[data['target']].series;
              currentSeries.show();
            });
  
            return series;
          }
  
          var regionalSeries = {};
          var currentSeries;
  
          function setupStores(data) {
            // Init country-level series
            regionalSeries['US'] = {
              markerData: [],
              series: createSeries("stores")
            };
  
  
            // Set current series
            currentSeries = regionalSeries['US'].series;
  
            // Process data
            am4core.array.each(data, function (store1) {
  
              // Get store data
              var store = {
                state: store1['state_abbreviation'],
                count: am4core.type.toNumber(store1['count'])
              };
  
              // Process state-level data
              if (regionalSeries[store['state']] == undefined) {
                var statePolygon = polygonSeries.getPolygonById("US-" + store['state']);
                if (statePolygon) {
  
                  // Add state data
                  regionalSeries[store['state']] = {
                    target: store['state'],
                    type: "state",
                    name: statePolygon.dataItem.dataContext['name'],
                    count: store['count'],
                    stores: store['count'],
                    lat: statePolygon.visualLatitude,
                    long: statePolygon.visualLongitude,
                    state: store['state'],
                    markerData: []
                  };
                  regionalSeries['US'].markerData.push(regionalSeries[store['state']]);
  
                }
                else {
                  // State not found
                  return;
                }
              }
              else {
                regionalSeries[store['state']].stores++;
                regionalSeries[store['state']].count += store['count'];
              }
  
              // Process city-level data
              if (regionalSeries[store['city']] == undefined) {
                regionalSeries[store['city']] = {
                  count: store['count'],
                  state: store['state'],
                  markerData: []
                };
                regionalSeries[store['state']].markerData.push(regionalSeries[store['city']]);
              }
              else {
                regionalSeries[store['city']].stores++;
                regionalSeries[store['city']].count += store['count'];
              }
  
              // Process individual store
              regionalSeries[store['city']].markerData.push({
                count: store['count'],
                state: store['state']
              });
  
            });
            regionalSeries['US'].series.data = regionalSeries['US'].markerData;
          }
  
        }); // end am4core.ready()
  
      }, 500);
    },
    (error) => {
      this.loader.recentlyConnectedOrganizations = false;
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });

    this.DashboardService.getStatewiseCountForAdmin({ 'type': 'byUsers' })
    .then((data) => {
      let org_data = data['data'];
      this.loader.stateWiseOrganizationsAndUsers = false;
   
      setTimeout(function () {

        am4core.ready(function () {
          // Create map instance
          var chart = am4core.create("map_chart_user", am4maps.MapChart);
          am4core.addLicense("CH236795548");
          chart.logo.hidden = true;
          chart.maxZoomLevel = 64;
          chart.responsive.enabled = true;
  
          // Set map definition
          chart.geodata = am4geodata_usaLow;
  
          // Set projection
          chart.projection = new am4maps.projections.AlbersUsa();
  
          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
          polygonSeries.useGeodata = true;
          polygonSeries.calculateVisualCenter = true;
  
          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.tooltipText = "{name}";
          polygonTemplate.fill = am4core.color("#209cb7");
  
          // Load data when map polygons are ready
          chart.events.on("ready", loadStores);
  
          // Loads store data
          function loadStores() {
            var loader = new am4core.DataSource();
            loader.data = org_data;
            setupStores(loader.data);  
            loader.load();
          }
  
          // Creates a series
          function createSeries(heatfield) {
            var series = chart.series.push(new am4maps.MapImageSeries());
            series.dataFields.value = heatfield;
  
            var template = series.mapImages.template;
            template.verticalCenter = "middle";
            template.horizontalCenter = "middle";
            template.propertyFields.latitude = "lat";
            template.propertyFields.longitude = "long";
            template.tooltipText = "{name}:\n[bold]{stores} Users[/]";
  
            var circle = template.createChild(am4core.Circle);
            circle.radius = 10;
            circle.fillOpacity = 0.7;
            circle.fill = am4core.color("#0c4aa3");
            circle.verticalCenter = "middle";
            circle.horizontalCenter = "middle";
            circle.nonScaling = true;
  
            var label = template.createChild(am4core.Label);
            label.text = "{stores}";
            label.fill = am4core.color("#fff");
            label.verticalCenter = "middle";
            label.horizontalCenter = "middle";
            label.nonScaling = true;
  
            var heat = series.heatRules.push({
              target: circle,
              property: "radius",
              min: 10,
              max: 30
            });
  
            // Set up drill-down
            series.mapImages.template.events.on("hit", function (ev) {
  
              // Determine what we've clicked on
              var data = ev.target.dataItem.dataContext;
  
              // No id? Individual store - nothing to drill down to further
              if (!data['target']) {
                return;
              }
  
              // Create actual series if it hasn't been yet created
              if (!regionalSeries[data['target']].series) {
                regionalSeries[data['target']].series = createSeries("count");
                regionalSeries[data['target']].series.data = data['markerData'];
              }  
  
              // Show new targert series
              currentSeries = regionalSeries[data['target']].series;
              currentSeries.show();
            });
  
            return series;
          }
  
          var regionalSeries = {};
          var currentSeries;
  
          function setupStores(data) {
            // Init country-level series
            regionalSeries['US'] = {
              markerData: [],
              series: createSeries("stores")
            };
  
  
            // Set current series
            currentSeries = regionalSeries['US'].series;
  
            // Process data
            am4core.array.each(data, function (store1) {
  
              // Get store data
              var store = {
                state: store1['state_abbreviation'],
                count: am4core.type.toNumber(store1['count'])
              };
  
              // Process state-level data
              if (regionalSeries[store['state']] == undefined) {
                var statePolygon = polygonSeries.getPolygonById("US-" + store['state']);
                if (statePolygon) {
  
                  // Add state data
                  regionalSeries[store['state']] = {
                    target: store['state'],
                    type: "state",
                    name: statePolygon.dataItem.dataContext['name'],
                    count: store['count'],
                    stores: store['count'],
                    lat: statePolygon.visualLatitude,
                    long: statePolygon.visualLongitude,
                    state: store['state'],
                    markerData: []
                  };
                  regionalSeries['US'].markerData.push(regionalSeries[store['state']]);
  
                }
                else {
                  // State not found
                  return;
                }
              }
              else {
                regionalSeries[store['state']].stores++;
                regionalSeries[store['state']].count += store['count'];
              }
  
              // Process city-level data
              if (regionalSeries[store['city']] == undefined) {
                regionalSeries[store['city']] = {
                  count: store['count'],
                  state: store['state'],
                  markerData: []
                };
                regionalSeries[store['state']].markerData.push(regionalSeries[store['city']]);
              }
              else {
                regionalSeries[store['city']].stores++;
                regionalSeries[store['city']].count += store['count'];
              }
  
              // Process individual store
              regionalSeries[store['city']].markerData.push({
                count: store['count'],
                state: store['state']
              });
  
            });
            regionalSeries['US'].series.data = regionalSeries['US'].markerData;
          }
  
        }); // end am4core.ready()
  
      }, 500);
    },
    (error) => {
      this.loader.recentlyConnectedOrganizations = false;
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
  }

}

