import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorClassService } from './http-interceptor-class.service';

// Components
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { PermissionNotFoundComponent } from './permission-not-found/permission-not-found.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { IntermediatePageComponent } from './intermediate-page/intermediate-page.component';
import { RegisterComponent } from './register/register.component';
import { SelectOrganizationComponent } from './select-organization/select-organization.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SystemWiseDetailsComponent } from './system-wise-details/system-wise-details.component';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';

// Services
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { RegistrationService } from './services/registration.service';
import { PasswordService } from './services/password.service';
import { ProfileService } from './services/profile.service';
import { DashboardService } from './services/dashboard.service';
import { IntermediatePageService } from './services/intermediate-page.service';

const routes: Routes = [
  { path: '', redirectTo: 'overview-dashboard', pathMatch: 'full' },
  
  { path: 'authentication', component: IntermediatePageComponent, canActivate: [AuthGuard],data: { title: 'Authentication' } },
  // { path: 'authentication/:token', component: IntermediatePageComponent, canActivate: [AuthGuard],data: { title: 'Authentication' } },
  { path: 'authentication/:token/organization-registration', component: IntermediatePageComponent, canActivate: [AuthGuard], data: { title: 'Authentication' } },
  { path: 'landing-dashboard', component: LandingDashboardComponent, canActivate: [AuthGuard], data: { title: 'Landing Dashboard' } },
  { path: 'admin/login', component: LoginComponent, canActivate: [AuthGuard], data: { title: 'Sign In' } },
  
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { title: 'Organization Dashboard' } },
  { path: 'overview-dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard], data: { title: 'Overview' } },
  // { path: 'organization-registration', component: RegisterComponent, canActivate: [AuthGuard],data: { title: 'Organization Registration' } },
  { path: 'system-wise-details', component: SystemWiseDetailsComponent, canActivate: [AuthGuard], data: { title: 'System wise Details' } },
  //{ path: 'select-organization', component: SelectOrganizationComponent, canActivate: [AuthGuard] },
  
  { path: 'permission-not-found', component: PermissionNotFoundComponent, data: { title: 'Permission Not Found' } },
  { path: 'page-not-found', component: NotFoundComponent, data: { title: 'Page Not Found' } },
  { path: 'authentication-failed', component: AuthenticationFailedComponent, data: { title: 'Authentication Failed' } },
  
  { path: 'manage', loadChildren: () => import('./app-manage/app-manage.module').then(m => m.AppManageModule) },
  { path: 'configuration', loadChildren: () => import('./app-configuration/app-configuration.module').then(m => m.AppConfigurationModule) },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [Globals, AuthService, AuthGuard, RegistrationService, PasswordService, ProfileService, 
    DashboardService, IntermediatePageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClassService,
      multi: true
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
